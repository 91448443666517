<template>
  <div class="orm-mb-16 orm-col-xs-12 " v-if="filter">
    <div class="orm-caroffers__element orm-px-32 orm-xs-py-24 orm-py-40">
      <div class="orm-row">
        <div
          class="orm-col-xs-12 orm-col-sm-12 orm-col-md-4 orm-mb-16 orm-border-right"
        >
          <div
            class="orm-slider__image__container orm-px-0"
            :style="[images ? { paddingBottom: 0 } : { paddingBottom: '75%' }]"
          >
            <Slider v-if="images" :images="images" :autoplay="false" />
          </div>
          <div class="orm-carequip text-center">
            <p class="orm-equip__txt__icons orm-mt-8">
              <equipIcon :data="data.features" :shortlist="true" />
            </p>
            <p class="orm-equip__txt__freemiles" v-if="price.length > 0">
              <span v-if="price && !price[0].all_km_inclusive"
                >{{ i18n("cargroup/inclusive") }} {{ price[0].km_free }}
                {{ i18n("cargroup/freeKilometers") }}</span
              >
              <span v-else
                >{{ i18n("cargroup/inclusive") }}
                {{ i18n("cargroup/unlimited") }}
                {{ i18n("cargroup/freeKilometers") }}</span
              >
            </p>
          </div>
        </div>
        <div
          class="orm-col-xs-12 orm-col-sm-12 orm-col-md-8 orm-col-lg-6 orm-px-8 orm-pl-md-32 orm-pr-md-24"
        >
          <div>
            <h2 class="orm-headline__h2 orm-headline__h2--list">
              {{ stripHTML(data[env.VUE_APP_RESERVATION_CARGROUP]) }}
            </h2>
            <p
              class="orm-headline__h2__subheadline--list orm-pb-16 orm-relative"
            >
              {{ i18n("cargroup/similar") }} |
              {{ stripHTML(data[env.VUE_APP_RESERVATION_CARCATEGORY]) }}
              <i class="fal fa-question-circle orm-cargroup__tooltip"
                ><span
                  class="orm-cargroup__tooltip_text orm-cargroup__tooltip-bottom--list"
                  >{{ i18n("cargroup/noguarantee") }}</span
                ></i
              >
            </p>
            <div
              v-html="data[env.VUE_APP_RESERVATION_CARTEXT]"
              class="orm-cardescription orm-mb-24"
            ></div>
            <div class="orm-availabillity__title orm-my-8">
              {{ i18n("cargroup/availabillity/title") }}
              <i class="fal fa-calendar-alt"></i>
            </div>
            <v-range-selector
              :start-date="
                store.getters['userSession/GET_RESERVATIONDATE'].start
              "
              :end-date="store.getters['userSession/GET_RESERVATIONDATE'].end"
              :disabled-dates="disabledDates"
              :min-date="minDate"
              :disabledWeekdays="disabledWeekdays"
              :singleMonth="false"
              :max-month-start="
                Math.round(
                  store.getters['ORMgeneral/GET_PARAMETER'](
                    'IINET_AVAILABILITY_PLAN_WEEKS_BEFOR'
                  ) / 4
                )
              "
              :max-month-end="
                Math.round(
                  store.getters['ORMgeneral/GET_PARAMETER'](
                    'IINET_AVAILABILITY_PLAN_WEEKS_AFTER'
                  ) / 4
                )
              "
              :availableplan="true"
              :occupied="occupied"
              :partially="partially"
            />
            <div class="orm-availabillity__legend orm-my-16">
              <span class="orm-availabillity__legend_item"
                ><span class="orm-availabillity__legend_selection"></span
                ><small>{{
                  i18n("cargroup/availabillity/selection")
                }}</small></span
              >
              <span class="orm-availabillity__legend_item"
                ><span class="orm-availabillity__legend_occupied"></span
                ><small>{{
                  i18n("cargroup/availabillity/occupied")
                }}</small></span
              >
              <span class="orm-availabillity__legend_item"
                ><span class="orm-availabillity__legend_partially"></span
                ><small>{{
                  i18n("cargroup/availabillity/partially")
                }}</small></span
              >
            </div>
          </div>
        </div>
        <div
          class="orm-col-xs-12 orm-col-md-12 orm-col-lg-2 text-right orm-pr-8 orm-pt-16"
        >
          <div class="orm-cardetails--flex">
            <div
              class="orm-cardetails__container orm-cardetails__container--bottom"
            >
              <div class="orm-cardetails end-xs">
                <div class="orm-carprice">
                  <div>
                    <p class="orm-price__txt__surtitle" v-if="price.length > 0">
                      {{ i18n("cargroup/unitbefore") }} {{ price[0].unit }}
                      {{ i18n("cargroup/unitafter") }}
                    </p>
                    <p class="orm-price__txt" v-if="price.length > 0">
                      <span style="white-space:nowrap;">{{
                        convertCurrency(price[0].long_price)
                      }}</span>
                    </p>

                    <p
                      class="orm-price__txt"
                      v-if="price.length == 0 && loading"
                    >
                      <i class="fa fa-spinner-third orm-rotate360"></i>
                    </p>

                    <p class="orm-price__txt__subtitle" v-if="price.length > 0">
                      ({{ i18n("cargroup/total") }}:
                      {{ convertCurrency(tarif.total_price_main_rates) }})
                    </p>
                  </div>
                </div>
              </div>
              <button
                v-if="price.length > 0 && !loading && availability.available"
                @click="setCarGroup(car)"
                class="orm-button__cta outline wide"
              >
                {{ i18n("cargroup/select") }}
              </button>
              <div class="orm-carprice">
                <div
                  class="orm-car__error"
                  v-if="price.length == 0 && !loading"
                >
                  {{ stripHTML(i18n("cargroup/error/price")) }}
                </div>
                <div
                  class="orm-car__error"
                  v-if="!availability.available && !loading"
                >
                  {{ stripHTML(i18n("cargroup/error/available")) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import store from "@/store";
import equipIcon from "@/components/reservation/equip-icon.vue";
import Slider from "@/components/ui/image-slider.vue";
import { convertCurrency, formateDate } from "@/constants/formater";
import VRangeSelector from "vuelendar/components/vl-range-selector";

export default defineComponent({
  name: "car-list-small",
  components: { equipIcon, Slider, VRangeSelector },
  setup() {
    const userSession = store.state.userSession.LocalSession;

    return {
      userSession,
      store,
      convertCurrency,
    };
  },
  data() {
    return {
      car: Object,
      price: [],
      images: null,
      show: true,
      loading: true,
      tarif: Object,
      availability: [],
      occupied: [],
      partially: [],
      minDate: null,
      disabledDates: [],
      disabledWeekdays: [],
      env: process.env,
    };
  },
  props: {
    data: Object,
    filter: Boolean,
  },
  async mounted() {
    this.car = await JSON.parse(JSON.stringify(this.data));

    this.car.price = {};

    const observer = new IntersectionObserver((entries) => {
      // Use `intersectionRatio` because of Edge 15's
      // lack of support for `isIntersecting`.
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      if (entries[0].intersectionRatio <= 0) return;
      // Cleanup the observer when it's not
      // needed anymore.
      observer.unobserve(this.$el);
      // The `loadPrice()` resolves
      // to the result of a dynamic `import()`
      // which is passed to the `resolveComponent()`
      // function.
      this.lazyload();
    });
    // We observe the root `$el` of the
    // mounted loading component to detect
    // when it becomes visible.
    observer.observe(this.$el);

    // console.log("this.availability", this.availability);

    const openeningTimes = await store.getters["stations/GET_OPENINGTIMES"];

    if (openeningTimes) {
      this.minDate = formateDate(openeningTimes.min_start_date);
      this.endtimeconfig = openeningTimes.end_time_config;
      this.starttimeconfig = openeningTimes.start_time_config;
    }
  },
  methods: {
    async lazyload() {
      // await store
      //   .dispatch("cargroup/SET_CARGROUP_IMAGE", this.car.id)
      //   .then((val) => {
      //     this.images = val;
      //     // console.log(val);
      //   });
      this.images = this.car.images;
      store.dispatch("cargroup/SET_AVAILABILITIES", this.car.id).then((res) => {
        // eslint-disable-next-line
        this.availability = res;
        res.availability_plan.forEach((element) => {
          // eslint-disable-next-line
          if (element.partially_booked) {
            this.partially.push(formateDate(element.date));
          } else {
            this.occupied.push(formateDate(element.date));
          }
        });
      });
      const car = [];
      this.tarif = await store
        .dispatch("reservation/CALCULATE_PRICE", {
          id: this.car.id,
          extras: false,
        })
        .then((res) => {
          if (!res.errors) {
            res.main_rates.forEach((element) => {
              if (element.position == 1) {
                car.push(element);
              }
            });
            this.loading = false;

            return res;
          } else {
            this.$emit("update:error", res);
          }
        });

      this.price = car;
    },
    setCarGroup(car) {
      store.dispatch("userSession/SET_CARGROUP", car.id).then(() => {
        store
          .dispatch("reservation/SET_RESERVATION_TARIFS", this.tarif)
          .then(() => {
            this.$router.push({ name: "Reservation_Step2" });
          });
      });
    },
  },
});
</script>
