<template>
  <ErrorModal v-if="error.show" :data="error.data" :load-times="true" />
  <reservationheader :step="2" />
  <reservationinfobar :step="2" />
  <div
    class="orm-container-fluid orm-reservation__data__container orm-reservation__data__container__extra"
    v-if="selectedCargroup && selectedCargroupInfo"
  >
    <div class="orm-container">
      <div class="orm-row">
        <div class="orm-col-xs-12 orm-col-md-9">
          <div class="orm-reservation__data__container__inner orm-my-16">
            <h1 class="orm-headline__h1">{{ i18n("step2/headline") }}</h1>
            <div class="orm-row">
              <div class="orm-col-xs-12 orm-col-sm-5">
                <Slider v-if="images" :images="images" />
                <div class="orm-carequip__centered">
                  <div class="orm-carequip">
                    <p
                      class="orm-equip__txt__icons orm-mt-8"
                      v-if="selectedCargroupInfo"
                    >
                      <equipIcon :data="selectedCargroupInfo.features" />
                    </p>
                  </div>
                </div>
              </div>

              <div class="orm-col-xs-12 orm-col-sm-7">
                <div class="orm-border-left">
                  <h2 class="orm-headline__h2">
                    {{ stripHTML(selectedCargroupInfo[env.VUE_APP_RESERVATION_CARGROUP]) }}
                  </h2>
                  <p class="orm-headline__h3__subheadline orm-relative">
                    {{ i18n("cargroup/similar") }} |
                    {{
                      stripHTML(selectedCargroupInfo[env.VUE_APP_RESERVATION_CARCATEGORY])
                    }}
                    <i class="fal fa-question-circle orm-cargroup__tooltip"
                      ><span
                        class="orm-cargroup__tooltip_text orm-cargroup__tooltip-bottom"
                        >{{ i18n("cargroup/noguarantee") }}</span
                      ></i
                    >
                  </p>
                  <div
                    v-html="
                      selectedCargroupInfo[env.VUE_APP_RESERVATION_CARTEXT]
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <!-- TARIFE START -->
          <div
            class="orm-reservation__data__container__inner orm-my-16"
            v-if="mainrates"
          >
            <div class="orm-row orm-relative">
              <div class="orm-col-xs-12">
                <h3 class="orm-headline__h3">{{ i18n("step2/tarifs") }}</h3>
                <div
                  v-for="mainrate in mainrates"
                  :key="mainrate"
                  class="orm-list__element"
                >
                  <div class="orm-row orm-my-16">
                    <div class="orm-col-xs-9 orm-list__bold">
                      <span v-if="mainrate.rate == 'KM'">
                        {{ mainrate.amount }}&nbsp;{{ mainrate.description }}
                        <span class="orm-small"
                          >({{ convertCurrency(mainrate.long_price) }}/ KM
                          )</span
                        >
                      </span>
                      <span v-else
                        >{{ mainrate.description }}
                        <span class="orm-small"
                          >({{ convertCurrency(mainrate.long_price) }}/{{
                            mainrate.unit
                          }})</span
                        >
                        <div
                          class="orm-small"
                          v-if="mainrate.description2"
                          v-html="mainrate.description2"
                        ></div>
                      </span>
                    </div>
                    <div class="orm-col-xs-3 orm-price__right">
                      {{ convertCurrency(mainrate.total_long_price) }}
                    </div>
                  </div>
                  <div v-if="mainrate.position == 1" class="orm-row orm-my-16">
                    <div
                      class="orm-col-xs-9 orm-list__bold"
                      v-if="!mainrate.all_km_inclusive"
                    >
                      {{ mainrate.km_free }} {{ i18n("step2/freeKilometers") }}
                    </div>
                    <div class="orm-col-xs-9 orm-list__bold" v-else>
                      {{ i18n("step2/unlimited") }}
                      {{ i18n("step2/freeKilometers") }}
                    </div>
                    <div class="orm-col-xs-3 orm-price__right">
                      {{ i18n("step2/inclusive") }}
                    </div>
                  </div>
                </div>
                <div v-if="loadingPrice" class="orm-extra__loading">
                  <div class="orm-loader-spinner">
                    <i class="fa fa-spinner-third orm-rotate360"></i>
                  </div>
                </div>
                <div class="orm-list__element">
                  <div class="orm-list__bold">
                    <label for="distance">{{ i18n("step2/distance") }}</label>
                    <div class="orm-input orm-inline orm-withIcon">
                      <input
                        class="orm-input__control"
                        id="distance"
                        type="text"
                        placeholder="KM"
                        v-model="distance"
                        @keyup.enter="setDistance()"
                        :disabled="loadingPrice"
                      />
                      <i class="far fa-sync" @click="setDistance()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="orm-row">
              <div class="orm-col-xs-12">
                <div class="orm-subtotal">
                  {{ i18n("general/subtotal") }}:
                  {{ convertCurrency(selectedCargroup.total_price_main_rates) }}
                </div>
              </div>
            </div>
          </div>
          <!-- PAYMENT START -->
          <div
            v-if="
              store.getters['ORMgeneral/GET_MODULE']('ConCardis') == 'J' &&
                mainrates
            "
          >
            <div
              class="orm-reservation__data__container__inner orm-my-16 orm-relative"
              v-if="mainrates"
            >
              <div v-if="loadingPrice" class="orm-extra__loading">
                <div class="orm-loader-spinner">
                  <i class="fa fa-spinner-third orm-rotate360"></i>
                </div>
              </div>
              <div class="orm-row orm-relative">
                <div class="orm-col-xs-12">
                  <h3 class="orm-headline__h3">{{ i18n("step2/payment") }}</h3>
                </div>
              </div>
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <span v-for="category in extras" :key="category">
                    <div
                      v-if="category.id == 'PAY_'"
                      class="orm-radio__element orm-mb-16"
                    >
                      <div
                        v-for="extra in category.extras"
                        :key="extra"
                        class="orm-radio__element"
                      >
                        <div class="orm-row orm-my-16">
                          <div class="orm-col-xs-9">
                            <form-radio
                              :my-multisize="true"
                              :my-multiline="true"
                              my-name="payment"
                              :my-label="extra.description"
                              :my-small="extra.description2"
                              :model-value="payment[extra.rate]"
                              @update:modelValue="
                                setPayment(extra.rate, extra.id, extra.amount)
                              "
                            />
                          </div>

                          <div class="orm-col-xs-3 orm-price__right">
                            <span v-if="extra.unit == 'X'">
                              {{ convertCurrency(extra.long_price) }}
                            </span>
                            <span v-else>
                              {{ convertCurrency(extra.long_price) }} /
                              {{ extra.unit }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- DELIVERY -->
          <div
            class="orm-reservation__data__container__inner orm-my-16"
            v-if="mainrates && hasDeliveryPickup"
          >
            <div class="orm-row orm-relative">
              <div class="orm-col-xs-12">
                <div v-if="loadingPrice" class="orm-extra__loading">
                  <div class="orm-loader-spinner">
                    <i class="fa fa-spinner-third orm-rotate360"></i>
                  </div>
                </div>
                <h3 class="orm-headline__h3">
                  {{ i18n("step2/deliverypickup/title") }}
                </h3>
                <div class="orm-row">
                  <div class="orm-col-xs-12 orm-col-md-10 orm-mb-16">
                    <div class="orm-mb-8">
                      <form-checkbox-simple
                        :my-name="i18n('step2/deliverypickup/delivery')"
                        :my-label="i18n('step2/deliverypickup/delivery')"
                        :my-multisize="false"
                        :model-value="delivery"
                        v-model="delivery"
                        @update:modelValue="validateDeliveryPickup"
                      ></form-checkbox-simple>
                    </div>
                    <div class="orm-form__container" v-if="delivery">
                      <form-input
                        my-id="deliveryStreet"
                        :my-label="i18n('step2/deliverypickup/deliveryStreet')"
                        :disabled="loadingPrice"
                        :error="deliveryPickupError.deliveryStreet"
                        :model-value="deliveryPickup.deliveryStreet"
                        v-model="deliveryPickup.deliveryStreet"
                      ></form-input>
                      <div class="orm-row">
                        <div class="orm-col-xs-12 orm-col-md-4">
                          <form-input
                            my-id="deliveryZipcode"
                            :my-label="
                              i18n('step2/deliverypickup/deliveryZipcode')
                            "
                            :disabled="loadingPrice"
                            :error="deliveryPickupError.deliveryZipcode"
                            :model-value="deliveryPickup.deliveryZipcode"
                            v-model="deliveryPickup.deliveryZipcode"
                          ></form-input>
                        </div>
                        <div class="orm-col-xs-12 orm-col-md-8">
                          <form-input
                            my-id="deliveryCity"
                            :my-label="
                              i18n('step2/deliverypickup/deliveryCity')
                            "
                            :disabled="loadingPrice"
                            :error="deliveryPickupError.deliveryCity"
                            :model-value="deliveryPickup.deliveryCity"
                            v-model="deliveryPickup.deliveryCity"
                          ></form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="orm-col-xs-12 orm-col-md-10 orm-mb-16">
                    <div class="orm-mb-8">
                      <form-checkbox-simple
                        :my-name="i18n('step2/deliverypickup/pickup')"
                        :my-label="i18n('step2/deliverypickup/pickup')"
                        :my-multisize="false"
                        :model-value="pickup"
                        v-model="pickup"
                        @update:modelValue="validateDeliveryPickup"
                      ></form-checkbox-simple>
                    </div>
                    <div class="orm-form__container" v-if="pickup">
                      <form-input
                        my-id="pickupStreet"
                        :my-label="i18n('step2/deliverypickup/pickupStreet')"
                        :disabled="loadingPrice"
                        :error="deliveryPickupError.pickupStreet"
                        :model-value="deliveryPickup.pickupStreet"
                        v-model="deliveryPickup.pickupStreet"
                      ></form-input>
                      <div class="orm-row">
                        <div class="orm-col-xs-12 orm-col-md-4">
                          <form-input
                            my-id="pickupZipcode"
                            :my-label="
                              i18n('step2/deliverypickup/pickupZipcode')
                            "
                            :disabled="loadingPrice"
                            :error="deliveryPickupError.pickupZipcode"
                            :model-value="deliveryPickup.pickupZipcode"
                            v-model="deliveryPickup.pickupZipcode"
                          ></form-input>
                        </div>
                        <div class="orm-col-xs-12 orm-col-md-8">
                          <form-input
                            my-id="pickupCity"
                            :my-label="i18n('step2/deliverypickup/pickupCity')"
                            :disabled="loadingPrice"
                            :error="deliveryPickupError.pickupCity"
                            :model-value="deliveryPickup.pickupCity"
                            v-model="deliveryPickup.pickupCity"
                          ></form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="orm-row">
                  <div class="orm-col-xs-12 orm-col-md-10 text-right">
                    <div class="orm-form__container">
                      <button
                        class="orm-button__cta outline small"
                        @click="validateDeliveryPickup"
                        v-if="pickup || delivery"
                      >
                        {{ i18n("step2/deliverypickup/calculate") }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="orm-row orm-mt-16">
                  <div class="orm-col-xs-12">
                    <div
                      class="orm-list__element"
                      v-for="rates in deliveryrates"
                      :key="rates"
                    >
                      <div class="orm-row orm-my-16">
                        <div class="orm-col-xs-9 orm-list__bold">
                          {{ rates.description }}: {{ rates.amount }}
                          {{ rates.unit }}
                        </div>
                        <div class="orm-col-xs-3 orm-price__right">
                          {{ convertCurrency(rates.total_long_price) }}
                        </div>
                      </div>
                    </div>

                    <div class="orm-subtotal  orm-mt-32">
                      {{ i18n("general/subtotal") }}:
                      {{
                        convertCurrency(
                          selectedCargroup.total_price_delivery_pickup
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- EXTRAS START -->
          <span v-for="category in extras" :key="category.id">
            <div
              v-if="category && category.id == 'CDW'"
              class="orm-reservation__data__container__inner orm-my-16"
            >
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <h3 class="orm-headline__h3">{{ category.name }}</h3>
                  <div class="orm-relative">
                    <div v-if="loadingPrice" class="orm-extra__loading">
                      <div class="orm-loader-spinner">
                        <i class="fa fa-spinner-third orm-rotate360"></i>
                      </div>
                    </div>
                    <div
                      v-if="insuranceRequired == false"
                      class="orm-radio__element"
                    >
                      <div class="orm-row orm_my-16">
                        <div class="orm-col-xs-9">
                          <form-radio
                            :my-multisize="true"
                            :my-multiline="true"
                            my-name="cdw"
                            :my-label="i18n('step2/insurance')"
                            :my-small="i18n('step2/insuranceSmall')"
                            :model-value="true"
                            @update:modelValue="setInsurance()"
                          ></form-radio>
                        </div>
                        <div class="orm-col-xs-3 orm-price__right">
                          {{ i18n("step2/inclusive") }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-for="extra in category.extras"
                      :key="extra"
                      class="orm-radio__element"
                    >
                      <div class="orm-row orm-my-16">
                        <div class="orm-col-xs-9">
                          <form-radio
                            v-if="insurance"
                            :my-multisize="true"
                            :my-multiline="true"
                            my-name="cdw"
                            :my-label="extra.description"
                            :my-small="extra.description2"
                            :model-value="insurance[extra.rate]"
                            @update:modelValue="
                              setInsurance(extra.rate, extra.id, extra.amount)
                            "
                          />
                          <form-radio
                            v-else
                            :my-multisize="true"
                            :my-multiline="true"
                            my-name="cdw"
                            :my-label="extra.description"
                            :my-small="extra.description2"
                            @update:modelValue="
                              setInsurance(extra.rate, extra.id, extra.amount)
                            "
                          />
                        </div>
                        <div class="orm-col-xs-3 orm-price__right">
                          <span v-if="extra.unit == 'MAX'">
                            {{ convertCurrency(extra.long_price) }}
                          </span>
                          <span v-else>
                            {{ convertCurrency(extra.long_price) }} /
                            {{ extra.unit }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="orm-row">
                    <div class="orm-col-xs-12">
                      <div class="orm-subtotal">
                        {{ i18n("general/subtotal") }}:
                        {{
                          convertCurrency(
                            selectedCargroup.total_price_insurance
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>

          <div
            class="orm-reservation__data__container__inner orm-mt-16 orm-mb-32"
            v-if="extras.length > 0"
          >
            <div class="orm-row">
              <div class="orm-col-xs-12">
                <div class="orm-row">
                  <div class="orm-col-xs-9" style="align-self:center;">
                    <h3 class="orm-headline__h3__extras">
                      {{ i18n("step2/extras") }}
                    </h3>
                  </div>
                  <div class="orm-col-xs-3 orm-price__right">
                    <div
                      v-if="extrasRequired == false"
                      class="orm-extras__button"
                    >
                      <i
                        :class="{
                          'fal fa-plus-circle orm-extras__button__closed': !extrasVisible,
                          'fal fa-minus-circle orm-extras__button__open': extrasVisible,
                        }"
                        @click="extrasVisible = !extrasVisible"
                      ></i>
                    </div>
                  </div>
                </div>

                <div
                  class="orm-relative orm-pt-16"
                  :class="{ 'orm-extras__container__closed': !extrasVisible }"
                >
                  <div v-if="loadingPrice" class="orm-extra__loading">
                    <div class="orm-loader-spinner">
                      <i class="fa fa-spinner-third orm-rotate360"></i>
                    </div>
                  </div>
                  <span v-for="category in extras" :key="category">
                    <span v-if="category.id == 'CDW' || category.id == 'PAY_'">
                    </span>
                    <div v-else class="orm-mb-32">
                      <p class="orm-headline__h3__subheadline orm-mb-8">
                        {{ category.name }}
                      </p>
                      <div
                        v-for="extra in category.extras"
                        :key="extra"
                        class="orm-row orm-mt-8 orm-mb-16"
                      >
                        <div class="orm-col-xs-9">
                          <form-checkbox
                            :my-name="extra.rate"
                            :my-label="extra.description"
                            :my-small="extra.description2"
                            :my-multisize="true"
                            :required="extra.required"
                            :model-value="selectedExtras[extra.rate]"
                            :amount-real="extra.amount_real"
                            :min-amount="extra.min_amount"
                            :max-amount="extra.max_amount"
                            @update:modelValue="
                              setExtra(extra.rate, extra.id, $event)
                            "
                          ></form-checkbox>
                        </div>
                        <div class="orm-col-xs-3 orm-price__right">
                          <span>
                            {{ convertCurrency(extra.long_price) }}
                            <span v-if="extra.unit != 'MAX'"
                              >/&nbsp;{{ extra.unit }}</span
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                  </span>
                  <div class="orm-row">
                    <div class="orm-col-xs-12">
                      <div class="orm-subtotal ">
                        {{ i18n("general/subtotal") }}:
                        {{
                          convertCurrency(selectedCargroup.total_price_subrates)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- EXTRAS END -->
        </div>
        <div class="orm-col-xs-12 orm-col-md-3">
          <Scrollcontainer>
            <div
              class="orm-priceCalculator"
              v-if="selectedCargroup.end_positions"
            >
              <!-- {{selectedCargroup.end_positions}} -->
              <div class="orm-relative">
                <div
                  v-if="loadingPrice"
                  class="orm-extra__loading orm-extra__loading--alt"
                >
                  <div class="orm-loader-spinner">
                    <i class="fa fa-spinner-third orm-rotate360"></i>
                  </div>
                </div>

                <p class="orm-pricetag">
                  {{ i18n("general/total") }}:
                  <em>{{
                    convertCurrency(
                      selectedCargroup.end_positions[2].total_price
                    )
                  }}</em>
                </p>
              </div>
              <p class="orm-smallprint">
                {{ selectedCargroup.end_positions[1].description }}
              </p>
              <button
                v-if="!loadingPrice"
                @click="setReservation"
                class="orm-button__cta outline small wide"
              >
                {{ i18n("general/nextstep") }}
              </button>
            </div>
          </Scrollcontainer>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="">
import { defineComponent } from "vue";
// import router from "@/router";
import reservationheader from "@/components/reservation/header.vue"; // @ is an alias to /src
import reservationinfobar from "@/components/reservation/infobar_disabled.vue"; // @ is an alias to /src
import equipIcon from "@/components/reservation/equip-icon.vue";
import Slider from "@/components/ui/image-slider.vue";
import Scrollcontainer from "@/components/ui/scrollcontainer.vue";
import FormInput from "@/components/ui/INPUTS/FormInput.vue"; // @ is an alias to /src
import FormCheckbox from "@/components/ui/INPUTS/FormCheckboxExtras.vue";
import FormCheckboxSimple from "@/components/ui/INPUTS/FormCheckbox.vue";
import FormRadio from "@/components/ui/INPUTS/FormRadio.vue";
import ErrorModal from "@/components/modals/modal-error.vue";

import { convertCurrency } from "@/constants/formater";

import store from "@/store";
import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src

export default defineComponent({
  name: "ReservationStep2",
  props: { data: Object, type: String },
  data() {
    return {
      env: process.env,
      stationSelected: store.getters["userSession/GET_STATION"],
      carID: String,
      selectedCargroup: Object,
      selectedDate: Object,
      selectedTime: Object,
      mainrates: Array,
      subrates: Array,
      deliveryrates: Array,
      endpositions: Array,
      selectedCargroupInfo: {},
      images: null,
      extras: Array,
      selectedExtras: store.getters["reservation/GET_EXTRAS"],
      distance: store.getters["reservation/GET_RESERVATION_DISTANCE"],
      convertCurrency,
      insuranceRequired: false,
      extrasRequired: false,
      testradio: true,
      extrasVisible: false,
      insurance: {},
      payment: {},
      loadingPrice: false,
      paymentCondardis: false,
      hasDeliveryPickup: false,
      deliveryPickup: {},
      delivery: false,
      pickup: false,
      deliveryPickupError: {
        deliveryStreet: false,
        deliveryZipcode: false,
        deliveryCity: false,
        pickupStreet: false,
        pickupZipcode: false,
        pickupCity: false,
      },
      error: {
        show: false,
        data: {
          header: this.i18n("error/tocarselect/header"),
          content: this.i18n("error/tocarselect/content"),
          buttonFirst: "Home",
          buttonFirstLabel: this.i18n("error/tocarselect/buttonFirstLabel"),
        },
      },
    };
  },
  components: {
    reservationheader,
    reservationinfobar,
    Footer,
    equipIcon,
    Slider,
    FormCheckbox,
    FormCheckboxSimple,
    FormRadio,
    FormInput,
    ErrorModal,
    Scrollcontainer,
  },
  setup() {
    return {
      store,
    };
  },
  async mounted() {
    if (process.env.VUE_APP_DELIVERYPICKUP == "true") {
      this.hasDeliveryPickup = true;
    }
    store.dispatch("payment/DELETE_CONARDIS");
    store.dispatch("reservation/DELETE_SUCCESS_RESERVATION");

    const deliveryPickup = await store.getters[
      "reservation/GET_DELIVERY_PICKUP"
    ];
    this.deliveryPickup = JSON.parse(JSON.stringify(deliveryPickup));
    this.delivery = deliveryPickup.deliveryStreet !== "" ? true : false;
    this.pickup = deliveryPickup.pickupStreet !== "" ? true : false;

    const carID = await store.getters["reservation/GET_RESERVATION_CARGROUP"];
    this.carID = carID;

    if (carID == null) {
      this.error.show = true;
      return false;
    }

    this.selectedCargroupInfo = await store.getters[
      "cargroup/GET_CARGROUP_BY_ID"
    ](carID);

    if (this.selectedCargroupInfo == undefined) {
      // console.log("this.selectedCargroupInfo", this.selectedCargroupInfo);
      this.error.show = true;
      return false;
    }

    this.images = await store.getters["cargroup/GET_GLOBAL_CARGROUPS_IMAGE"](
      carID
    );
    this.selectedCargroup = await store.getters["reservation/GET_RESERVATION"];

    if (this.selectedCargroup == null) {
      this.error.show = true;
      return false;
    }

    this.selectedDate = await store.getters["reservation/GET_RESERVATION_DATE"];

    if (this.selectedDate == null) {
      this.error.show = true;
      return false;
    }

    this.selectedTime = await store.getters["reservation/GET_RESERVATION_TIME"];

    if (this.selectedTime == null) {
      this.error.show = true;
      return false;
    }

    this.subrates = this.selectedCargroup.sub_rates;
    this.mainrates = this.selectedCargroup.main_rates;
    this.mainrates.sort((a, b) => (a.position > b.position ? 1 : -1));
    this.deliveryrates = this.selectedCargroup.delivery_pickup_rates;
    this.endpositions = this.selectedCargroup.end_positions;

    const extras = await store.getters["reservation/GET_EXTRA_CATEGORIES"];

    if (extras == null) {
      await store
        .dispatch(
          "reservation/SET_CATEGORIES",
          this.selectedCargroup.main_rates[0]
        )
        .then((res) => {
          this.extras = res;
          this.selectedExtras = store.getters["reservation/GET_EXTRAS"];

          if (Object.keys(this.selectedExtras).length > 0) {
            this.extrasVisible = true;
            this.calcPrice();
          }
        });
    } else {
      this.extras = extras;
      this.selectedExtras = await store.getters["reservation/GET_EXTRAS"];
      if (Object.keys(this.selectedExtras).length > 0) {
        this.extrasVisible = true;
      }
    }

    this.payment = await store.getters["reservation/GET_PAYMENTTYPE"];
    this.insurance = await store.getters["reservation/GET_INSURANCE"];

    if (
      this.payment &&
      this.subrates.length == 0 &&
      store.getters["ORMgeneral/GET_MODULE"]("ConCardis") == "J"
    ) {
      this.calcPrice();
    }

    this.extras.forEach((element) => {
      element.extras.forEach((element) => {
        if (element.required == true) {
          if (element.category == 6) {
            this.insuranceRequired = true;
          }
        }
      });
    });
  },
  methods: {
    async calcPrice() {
      this.loadingPrice = true;
      // console.log(this.carID);
      this.selectedCargroup = await store
        .dispatch("reservation/CALCULATE_PRICE", {
          id: this.carID,
          extras: true,
        })
        .then((res) => {
          if (!res.errors) {
            store.dispatch("reservation/SET_RESERVATION_TARIFS", res);
            this.subrates = res.sub_rates;
            this.mainrates = JSON.parse(JSON.stringify(res.main_rates));
            this.deliveryrates = JSON.parse(
              JSON.stringify(res.delivery_pickup_rates)
            );
            this.endpositions = res.end_positions;
            this.mainrates.sort((a, b) => (a.position > b.position ? 1 : -1));
            this.loadingPrice = false;
            return res;
          } else {
            console.log("errors", res);
          }
        });
    },
    setDistance() {
      this.loadingPrice = true;
      store
        .dispatch("reservation/SET_DRIVEDISTANCE", this.distance)
        .then((res) => {
          this.calcPrice();
          return res;
        });
    },
    setInsurance(rate, id, amount) {
      this.loadingPrice = true;
      store
        .dispatch("reservation/SET_INSURANCE", { rate, id, amount })
        .then((res) => {
          this.calcPrice();
          return res;
        });
    },
    setPayment(rate, id, amount) {
      this.loadingPrice = true;
      store
        .dispatch("reservation/SET_PAYMENTTYPE", { rate, id, amount })
        .then((res) => {
          this.calcPrice();
          return res;
        });
    },
    setExtra(rate, id, amount) {
      this.loadingPrice = true;
      store
        .dispatch("reservation/SET_EXTRAS", { rate, id, amount })
        .then((res) => {
          this.calcPrice();
          this.selectedExtras = store.getters["reservation/GET_EXTRAS"];
          return res;
        });
    },
    async setReservation() {
      await store
        .dispatch("reservation/CALCULATE_PRICE", {
          id: this.carID,
          extras: true,
        })
        .then((res) => {
          if (!res.errors) {
            if (
              res.end_positions[2].total_price !==
              this.endpositions[2].total_price
            ) {
              console.log("end check calc", res.end_positions[2].total_price);
              console.log("end old calc", this.endpositions[2].total_price);
              console.log("ns check calc", res.end_positions[0].total_price);
              console.log("ns old calc", this.endpositions[0].total_price);
              console.log("all check calc", res);
              console.log(
                "all old calc",
                JSON.parse(JSON.stringify(this.selectedCargroup))
              );
              this.error.show = true;
              this.error.data.header = this.i18n("error/wrongpricecalc/header");
              this.error.data.content = this.i18n(
                "error/wrongpricecalc/content"
              );
              this.error.data.buttonFirst = "Reservation_Step1";
            } else {
              store
                .dispatch(
                  "reservation/SET_RESERVATION_TARIFS",
                  this.selectedCargroup
                )
                .then(() => {
                  this.$router.push({ name: "Reservation_Step3" });
                });
            }
          } else {
            this.error.show = true;
          }
        });
    },
    validateDeliveryPickup() {
      let error = false;

      if (this.delivery) {
        if (this.deliveryPickup.deliveryStreet == "") {
          error = true;
          this.deliveryPickupError.deliveryStreet = true;
        } else {
          this.deliveryPickupError.deliveryStreet = false;
        }
        if (this.deliveryPickup.deliveryZipcode == "") {
          error = true;
          this.deliveryPickupError.deliveryZipcode = true;
        } else {
          this.deliveryPickupError.deliveryZipcode = false;
        }
        if (this.deliveryPickup.deliveryCity == "") {
          error = true;
          this.deliveryPickupError.deliveryCity = true;
        } else {
          this.deliveryPickupError.deliveryCity = false;
        }
      } else {
        this.deliveryPickup.deliveryStreet = "";
        this.deliveryPickup.deliveryZipcode = "";
        this.deliveryPickup.deliveryCity = "";
      }
      if (this.pickup) {
        if (this.deliveryPickup.pickupStreet == "") {
          error = true;
          this.deliveryPickupError.pickupStreet = true;
        } else {
          this.deliveryPickupError.pickupStreet = false;
        }
        if (this.deliveryPickup.pickupZipcode == "") {
          error = true;
          this.deliveryPickupError.pickupZipcode = true;
        } else {
          this.deliveryPickupError.pickupZipcode = false;
        }
        if (this.deliveryPickup.pickupCity == "") {
          error = true;
          this.deliveryPickupError.pickupCity = true;
        } else {
          this.deliveryPickupError.pickupCity = false;
        }
      } else {
        this.deliveryPickup.pickupStreet = "";
        this.deliveryPickup.pickupCity = "";
        this.deliveryPickup.pickupCity = "";
      }

      if (!error) {
        store
          .dispatch("reservation/SET_DELIVERY_PICKUP", this.deliveryPickup)
          .then(() => {
            this.calcPrice();
          });
      }
    },
  },
});
</script>
