<template>
  <teleport to="body">
    <div class="orm-html orm-modal">
      <div class="orm-container">
        <div class="orm-row center-xs middle-xs orm-h100vh">
          <div class="orm-col-xs-12 orm-col-sm-8">
            <div
              class="orm-modal__inner orm-modal__inner--fullheight orm-py-30 orm-px-30"
            >
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <h2 class=".orm-headline__h2">
                    <span v-if="!modelValue.error">{{
                      i18n("payment/title")
                    }}</span>
                    <span v-else>{{ i18n("payment/title_error") }}</span>
                    <span
                      v-if="!modelValue.error && !modelValue.loading"
                      @click="cancel()"
                      class="btn__small--black float-right"
                    >
                      {{ i18n("payment/btn_cancel") }} X
                    </span>
                  </h2>
                </div>
                <div class="orm-col-xs-12 orm-mb-16 orm-relative">
                  <div class="orm-extra__loading" v-if="modelValue.loading">
                    <div class="orm-loader-spinner">
                      <i class="fa fa-spinner-third orm-rotate360"></i>
                    </div>
                  </div>
                  <div v-if="modelValue.error">
                    <p>
                      {{ i18n("payment/error") }}
                    </p>
                    <div class="orm-row orm-mt-32">
                      <div class="orm-col-xs-12">
                        <button
                          class="orm-button__cta large  orm-mr-0 orm-mr-sm-16 orm-mb-16 orm-mb-md-0 "
                          @click="retry()"
                        >
                          {{ i18n("payment/btn_retry") }}
                        </button>
                        <button
                          class="orm-button__cta outline large"
                          @click="cancel()"
                        >
                          {{ i18n("payment/btn_cancel") }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      id="inline-widget-container"
                      class="orm-payment__container"
                      :style="[modelValue.loading ? { opacity: 0 } : { opacity: 1 }]"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="orm-row center-xs">
                <div class="orm-col-xs-8 text-center">
                  <button
                    v-if="!modelValue.error && !modelValue.loading"
                    @click="pay()"
                    class="orm-button__cta orm-btn--large"
                  >
                    {{ i18n("payment/btn_pay") }} ({{
                      convertCurrency(modelValue.data.initialAmount / 100)
                    }})
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
// import routes from "@/router/index";
// import store from "@/store";

import { convertCurrency } from "@/constants/formater";

export default {
  name: "modal-concardis",
  emits: ["retry", "cancel", "pay"],
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      convertCurrency,
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  unmounted() {
    document.body.style.overflow = "auto";
  },
  methods: {
    retry() {
      // console.log("retry");
      this.$emit("retry");
    },
    cancel() {
      // console.log("cancel");
      this.$emit("cancel");
    },
    pay() {
      // console.log("pay");
      this.loading = true;
      this.$emit("pay");
    },
  },
};
</script>
