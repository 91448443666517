
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    return { store };
  },
  data() {
    return {
      initApp: false,
      initORM: false,
      initORMADDS: false,
      error: "",
      bar: false,
      loading: store.getters["general/GET_LOADING"],
      merchantId: process.env.VUE_APP_CONCARDIS_MERCHANT_ID,
      maps: process.env.VUE_APP_MAPS_API_KEY,
      logo: null,
      logoheight: process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_LOADING,
      logowidth: process.env.VUE_APP_CUSTOMER_LOGO_WIDTH_LOADING,
    };
  },
  async mounted() {
    console.log(
      "%c" + "C-Rent - loading",
      "line-height: 30px; font-size: 16px; border-radius: .25em;background-color: rgb(230, 7, 11); color: #ffff; padding: 3px .5em;"
    );

    store.watch(
      (state, getters) => getters["general/GET_LOADING"],
      (val) => {
        this.loading = val;
        console.log(val);
      }
    );
    await store.dispatch("general/initAPP").then((res) => {
      if (res) {
        this.initApp = true;
      } else {
        this.error = "ORM app not loaded, please try later";
      }
    });
    if (!this.initApp) return false;

    this.logo =
      store.getters["general/GET_CLIENT_DATA"]["reservationheader-logo"];

    await store.dispatch("general/initORMCORE").then((res) => {
      if (res) {
        this.initORM = true;
      } else {
        this.error = "ORM Core not loaded, please try later";
      }
    });
    await store.dispatch("general/initORMADDS").then((res) => {
      if (res) {
        this.initORMADDS = true;
      } else {
        this.error = "ORM Addons not loaded, please try later";
      }
    });

    console.log(
      "%c" + "Linienflug Design ✈ Weil bewegt bewegt.",
      "line-height: 30px; font-size: 18px; border-radius: .25em;background-color: #2b3846; color: #ffa300; padding: 3px .5em;"
    );
  },
  methods: {
    calcloading() {
      return Math.floor((this.loading.from / this.loading.to) * 100);
    },
  },
});
