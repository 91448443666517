<template>
  <div
    id="datepicker"
    class="orm-datepicker"
    :class="{ 'datepicker__mapModule orm-row': viewType == 'mapModule' }"
    v-click-outside="handleClick"
  >
    <div
      :class="{
        'orm-datecol orm-pr-sm-8': viewType !== 'mapModule',
        'orm-datecol--map orm-col-xs-12': viewType == 'mapModule',
      }"
    >
      <div class="orm-searchbar__headline" v-if="viewType !== 'infobar'">
        <strong>{{ i18n("general/pickup") }}</strong>
      </div>
      <div class="orm-dateselect orm-row no-margin">
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div>
            <small>{{ i18n("general/date") }}</small>
          </div>
          <div
            class="orm-datepicker__input orm-pr-16"
            @click="changeDate = 'start'"
            id="start"
            :class="[
              changeDate == 'start' ? 'active' : '',
              station ? 'enable' : 'disable',
            ]"
          >
            {{
              formateDateShort(
                store.getters["userSession/GET_RESERVATIONDATE"].start
              )
            }}
            <i class="fal fa-calendar-alt"></i>
          </div>
        </div>
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div>
            <div>
              <small>{{ i18n("general/time") }}</small>
            </div>
            <div v-if="station">
              <timepicker
                v-if="openingSelctedStart"
                :endtime="false"
                :selected="openingSelctedStart[1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'orm-datecol orm-pl-sm-8': viewType !== 'mapModule',
        'orm-datecol--map orm-col-xs-12': viewType == 'mapModule',
      }"
    >
      <div class="orm-searchbar__headline" v-if="viewType !== 'infobar'">
        <strong>{{ i18n("general/delivery") }}</strong>
      </div>
      <div class="orm-dateselect orm-row no-margin">
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div>
            <small>{{ i18n("general/date") }}</small>
          </div>
          <div
            class="orm-datepicker__input orm-pr-16"
            @click="changeDate = 'end'"
            id="end"
            :class="[
              changeDate == 'end' ? 'active' : '',
              station ? 'enable' : 'disable',
            ]"
          >
            {{
              formateDateShort(
                store.getters["userSession/GET_RESERVATIONDATE"].end
              )
            }}
            <i class="fal fa-calendar-alt"></i>
          </div>
        </div>
        <div class="orm-col-xs-6 orm-col-md-6 orm-px-8">
          <div>
            <div>
              <small>{{ i18n("general/time") }}</small>
            </div>
            <div v-if="station">
              <timepicker
                v-if="openingSelctedStart"
                :endtime="true"
                :selected="openingSelctedEnd[1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-range-selector
      v-if="changeDate"
      v-model:start-date="
        store.getters['userSession/GET_RESERVATIONDATE'].start
      "
      v-model:end-date="store.getters['userSession/GET_RESERVATIONDATE'].end"
      v-model:changeDate="changeDate"
      :teleportTo="vDatepickerTeleport"
      :disabled-dates="disabledDates"
      :min-date="minDate"
      :disabledWeekdays="disabledWeekdays"
      :singleMonth="viewType == 'mapModule' ? true : false"
      max-month-start="0"
      max-month-end="24"
      @update:startDate="changeStart"
      @update:endDate="changeEnd"
    />
  </div>
</template>

<script>
import VRangeSelector from "vuelendar/components/vl-range-selector";
import timepicker from "@/components/ui/timepicker.vue"; // @ is an alias to /src
import store from "@/store";

import {
  formateDate,
  formatDayOfWeek,
  formateDateShort,
} from "@/constants/formater";

export default {
  components: {
    VRangeSelector,
    timepicker,
  },
  setup() {
    return {
      formateDateShort,
      formatDayOfWeek,
      store,
    };
  },
  data() {
    return {
      range: Object,
      pickerRange: Object,
      date: null,
      minDate: null,
      station: null,
      disabledDates: [],
      disabledWeekdays: [],
      schedule: null,
      changeDate: null,
      openingStartMin: null,
      openingEndMin: null,
      openingSelctedStart: null,
      openingSelctedEnd: null,
      endtimeconfig: null,
      starttimeconfig: null,
    };
  },
  props: {
    viewType: String,
    vDatepickerTeleport: String,
    infobar: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.range = await store.getters["userSession/GET_RESERVATIONDATE"];
    this.initStartEndDate();

    const station = await store.getters["userSession/GET_STATION"];
    this.disabledDates = store.getters["stations/GET_CLOSEDDAYS"];

    if (station) {
      this.station = await store.getters["stations/GET_STATION_BY_ID"](station);
    }

    store.watch(
      (getters) => getters["userSession/GET_STATION"],
      (val) => {
        this.station = store.getters["stations/GET_STATION_BY_ID"](val);
        this.initStartEndDate();
      }
    );
  },
  methods: {
    handleClick(ev) {
      if (!ev.target.classList.contains("vl-calendar__arrow")) {
        this.changeDate = null;
      }
    },
    changeStart(event) {
      this.pickerRange.start = event;
      store.commit("userSession/MUTATE_DATE", this.pickerRange);
      this.range = store.getters["userSession/GET_RESERVATIONDATE"];

      store.dispatch("stations/SET_OPENINGTIMES").then(() => {
        this.setOpeneningTimes();
      });
    },
    changeEnd(event) {
      this.pickerRange.end = event;
      store.commit("userSession/MUTATE_DATE", this.pickerRange);
      this.range = store.getters["userSession/GET_RESERVATIONDATE"];
      store.dispatch("stations/SET_OPENINGTIMES").then(() => {
        this.setOpeneningTimes();
      });
    },
    // eslint-disable-next-line
    initStartEndDate() {
      this.setOpeneningTimes();
    },
    async setOpeneningTimes() {
      const openeningTimes = await store.getters["stations/GET_OPENINGTIMES"];
      this.schedule = openeningTimes;

      this.range = await store.getters["userSession/GET_RESERVATIONDATE"];
      this.pickerRange = { start: this.range.start, end: this.range.end };

      if (openeningTimes) {
        this.openingSelctedStart = openeningTimes.selected_start_date.split(
          "T"
        );
        this.openingSelctedEnd = openeningTimes.selected_end_date.split("T");
        this.openingStartMin = openeningTimes.min_start_date.split("T");
        this.openingEndMin = openeningTimes.min_end_date.split("T");
        this.minDate = formateDate(openeningTimes.min_start_date);

        this.endtimeconfig = openeningTimes.end_time_config;
        this.starttimeconfig = openeningTimes.start_time_config;
      }
    },
  },
};
</script>
