<template>
  <div class="orm-slider orm-slider--carcategory" v-if="carcategory">
    <div class="orm-row center-xs">
      <div class="orm-col-xs-12 orm-col-md-10">
        <transition-group
          name="fade"
          tag="div"
          class="orm-slider__inner orm-row center-xs start-sm"
        >
          <div
            v-for="(item, index) in carcategory"
            :key="item"
            class="orm-col-xs-8 orm-col-md-6 orm-col-lg-4 orm-slider__slide orm-slider__slide--offers orm-slider__slide--hide"
            :class="
              currentIndex <= index && currentIndex + (displayed - 1) >= index
                ? ' show'
                : ''
            "
          >
            <div class="text-center">
              <div class="orm-slider__imagecontainer">
                <img
                  v-if="item.images.length > 0"
                  :src="encodeURI(item.images[0].image)"
                  alt=""
                />
              </div>
              <div class="orm-px-sm-56 orm-pt-16">
                <div class="">
                  <div class="orm-cattitle orm-mb-16" v-if="!showCategory">
                    {{ stripHTML(item.text_web) }}
                  </div>
                  <div class="orm-cattitle orm-mb-16" v-else>
                    {{ stripHTML(item.category) }}
                  </div>
                  <button
                    class="orm-btn orm-btn--outline"
                    @click="select(item)"
                  >
                    {{ i18n("modules/carcategory/button") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <div
      class="orm-slider__controll"
      v-if="carcategory.length > 1 && controlls"
      :class="{ 'orm-slider__controll--fade': controllsFade }"
    >
      <div v-if="arrows">
        <div
          class="orm-slider__controll_arrows orm-slider__controll_arrows--left"
          @click="prev"
        >
          <i class="fal fa-chevron-circle-left"></i>
        </div>
        <div
          class="orm-slider__controll_arrows orm-slider__controll_arrows--right"
          @click="next"
        >
          <i class="fal fa-chevron-circle-right"></i>
        </div>
      </div>
      <div class="orm-slider__controll__dots" v-if="dots">
        <div
          v-for="(i, index) in carcategory"
          :key="i"
          class="orm-slider__controll__dot"
          :class="{
            'orm-slider__controll__dot--active': currentIndex == index,
          }"
          @click="showItem(index)"
        ></div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal && selectedCargroup"
    :car="selectedCargroup"
    :show-category="showCategory"
    @cancel="cancel()"
  />
</template>
<script>
import Modal from "@/components/modals/modal-stationtimeselect.vue";

export default {
  name: "OffersSliderComponent",
  components: { Modal },
  props: {
    carcategory: null,
    ratio: {
      type: String,
      required: false,
      default: "4/3",
    },
    controlls: {
      type: Boolean,
      required: false,
      default: true,
    },
    controllsFade: {
      type: Boolean,
      required: false,
      default: false,
    },
    dots: {
      type: Boolean,
      required: false,
      default: false,
    },
    arrows: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    },
    intervall: {
      type: Number,
      required: false,
      default: 6000,
    },
    lightbox: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: window.innerHeight,
      timer: null,
      displayed: 3,
      currentIndex: 0,
      items: null,
      initialX: null,
      initialY: null,
      showModal: false,
      selectedCargroup: null,
    };
  },

  mounted() {
    // console.log(this.carcategory);
    if (!this.carcategory) return;
    this.items = this.carcategory.length - 1;
    // console.log(this.items);
    if (this.carcategory.length > 1 && this.autoplay) this.startSlide();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.onResize();

    this.$el.parentNode.addEventListener("touchstart", this.startTouch, false);
    this.$el.parentNode.addEventListener("touchmove", this.moveTouch, false);
    this.$el.parentNode.addEventListener("mousedown", this.startTouch, false);
    this.$el.parentNode.addEventListener("mouseup", this.moveTouch, false);
    this.$el.parentNode.addEventListener(
      "mouseenter",
      () => {
        if (this.carcategory.length > 1 && this.autoplay)
          clearInterval(this.timer);
      },
      false
    );
    this.$el.parentNode.addEventListener(
      "mouseleave",
      () => {
        if (this.carcategory.length > 1 && this.autoplay)
          this.timer = setInterval(this.next, this.intervall);
      },
      false
    );
  },

  unmounted() {
    this.$el.parentNode.removeEventListener(
      "touchstart",
      this.startTouch,
      false
    );
    this.$el.parentNode.removeEventListener("touchmove", this.moveTouch, false);
    this.$el.parentNode.removeEventListener(
      "mousedown",
      this.startTouch,
      false
    );
    this.$el.parentNode.removeEventListener("mouseup", this.moveTouch, false);
    this.$el.parentNode.removeEventListener("mouseenter", this.startTouch);
    this.$el.parentNode.removeEventListener("mouseleave", this.moveTouch);
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 1080 && this.windowWidth > 768) {
        this.displayed = 2;
      } else if (this.windowWidth <= 768) {
        this.displayed = 1;
      } else {
        this.displayed = 3;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    startSlide() {
      this.timer = setInterval(this.next, this.intervall);
    },
    select(item) {
      this.selectedCargroup = item;
      this.showModal = true;
    },
    cancel() {
      this.selectedCargroup = null;
      this.showModal = false;
    },
    next() {
      this.currentIndex += this.displayed;
      if (this.currentIndex > this.items) this.currentIndex = 0;
    },
    prev() {
      this.currentIndex -= this.displayed;
      if (this.currentIndex < 0) {
        this.currentIndex = this.items - (this.items % this.displayed);
      }
    },
    showItem(index) {
      this.currentIndex = index;
      if (this.carcategory.length > 1 && this.autoplay) {
        clearInterval(this.timer);
        this.timer = setInterval(this.next, this.intervall);
      }
    },
    calcRatio(ratio) {
      const splitRatio = ratio.split("/");
      const calc = (splitRatio[1] / splitRatio[0]) * 100;
      return calc.toFixed(2);
    },
    startTouch(e) {
      if (this.carcategory.length > 1 && this.autoplay)
        clearInterval(this.timer);
      if (e.type == "mousedown") {
        this.initialX = e.clientX;
        this.initialY = e.clientY;
      } else {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
      }
    },
    moveTouch(e) {
      if (this.initialX === null) {
        return;
      }

      if (this.initialY === null) {
        return;
      }
      let currentX = 0;
      let currentY = 0;
      if (e.type == "mouseup") {
        currentX = e.clientX;
        currentY = e.clientY;
      } else {
        currentX = e.touches[0].clientX;
        currentY = e.touches[0].clientY;
      }

      const diffX = this.initialX - currentX;
      const diffY = this.initialY - currentY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        // sliding horizontally
        if (diffX > 0) {
          this.next();
        } else {
          this.prev();
        }
      } else {
        // sliding vertically
        if (diffY > 0) {
          // swiped up
          // console.log("swiped up");
        } else {
          // swiped down
          // console.log("swiped down");
        }
      }

      this.initialX = null;
      this.initialY = null;
      if (this.carcategory.length > 1 && this.autoplay) {
        this.timer = setInterval(this.next, this.intervall);
      }

      e.preventDefault();
    },
  },

  computed: {
    currentImg: function() {
      return this.carcategory[
        Math.abs(this.currentIndex) % this.carcategory.length
      ];
    },
  },
};
</script>
