import apiCall from "../../api/crentAPI";
import dollarsToCents from "dollars-to-cents";
import store from "@/store";

const ENV = process.env.NODE_ENV === "production" ? "ORM_" : "";

const state = () => ({});
const getters = {
  GET_ORDER: (state) =>
    state.payment ||
    JSON.parse(sessionStorage.getItem(ENV + "concardisPayment")),
};
const actions = {
  async GET_CONCARDIS_MERCHANDID() {
    const stationId = await store.getters["userSession/GET_STATION"];
    let result = "";
    await apiCall.getConcardis(
      `/api/rent/payment/${stationId}/configuration`,
      "",
      (response) => {
        if (response.status === 200) {
          result = response.data.concardis["merchant_id"];
        } else {
          result = "error";
        }
      }
    );
    return result;
  },
  async MAKE_ORDER({ commit }) {
    const stationId = await store.getters["userSession/GET_STATION"];
    const reservation = await store.getters["reservation/GET_RESERVATION"];
    const endpositions = reservation.end_positions;
    const amount =
      process.env.VUE_APP_DEMO === "true"
        ? 111
        : dollarsToCents(endpositions[2].total_price);
    const payload = {
      initialAmount: amount,
      currency: "EUR",
      transactionType: "DEBIT",
      ipAddress: null,
      async: {
        successUrl:
          window.location.protocol +
          "//" +
          window.location.hostname +
          process.env.VUE_APP_BASE_URL +
          process.env.VUE_APP_RESERVATION_PATH +
          process.env.VUE_APP_RESERVATION_PATH_FINAL,
        failureUrl:
          window.location.protocol +
          "//" +
          window.location.hostname +
          process.env.VUE_APP_BASE_URL +
          process.env.VUE_APP_RESERVATION_PATH +
          process.env.VUE_APP_RESERVATION_PATH_STEP4,
        cancelUrl:
          window.location.protocol +
          "//" +
          window.location.hostname +
          process.env.VUE_APP_BASE_URL +
          process.env.VUE_APP_RESERVATION_PATH +
          process.env.VUE_APP_RESERVATION_PATH_STEP4,
      },
      terms: Date.now(),
      privacy: Date.now(),
    };
    let order = "";
    await apiCall.postConcardis(
      `/api/rent/payment/${stationId}/orders`,
      JSON.stringify(payload),
      (result) => {
        order = result.data;
        if (result.status === 200) {
          commit("MUTATE_PAYMENT", result.data);
        }
      }
    );
    return order;
  },
  async SET_ORDER({ commit }) {
    let order = null;
    const stationId = await store.getters["userSession/GET_STATION"];
    const payload = {};
    const payment = await store.getters["payment/GET_ORDER"];
    if (payment !== null) {
      await apiCall.getConcardis(
        `/api/rent/payment/${stationId}/orders/` + payment.orderId,
        payload,
        (result) => {
          order = result.data;
          if (result.status === 200) {
            commit("MUTATE_PAYMENT", result.data);
          }
        }
      );
    }

    return order;
  },
  async REFUND_ORDER({ commit }) {
    let order = "";
    const stationId = await store.getters["userSession/GET_STATION"];
    const payment = await store.dispatch("payment/SET_ORDER");

    if (payment !== null) {
      const payload = {
        initialAmount: payment.initialAmount,
        currency: payment.currency,
      };
      const orderId = payment.orderId;
      const transactionId = payment.transactions[0].transactionId;
      await apiCall.postConcardis(
        `/api/rent/payment/${stationId}/orders/` +
          orderId +
          "/transactions/" +
          transactionId +
          "/refund",
        JSON.stringify(payload),
        (result) => {
          order = result;
        }
      );
    }
    commit("DELETE");
    return order;
  },
  DELETE_CONARDIS({ commit }) {
    commit("DELETE");
  },
};

// mutations
const mutations = {
  MUTATE_PAYMENT(state, payment) {
    state.payment = payment;
    sessionStorage.setItem(ENV + "concardisPayment", JSON.stringify(payment));
  },
  DELETE(state) {
    delete state.payment;
    sessionStorage.removeItem(ENV + "concardisPayment");
  },
};
export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
