<template>
  <teleport to="body">
    <div
      class="orm-html orm-modal orm-modal--stationtime orm-modal--overflowAuto"
    >
      <div class="orm-container">
        <div class="orm-row center-xs middle-xs orm-h100vh">
          <div class="orm-col-xs-12 orm-col-sm-10 orm-col-md-6 orm-col-lg-4">
            <div
              class="orm-modal__inner orm-modal__inner--fullheight orm-pt-60 orm-pb-30 orm-px-30 orm-relative"
            >
              <span
                v-if="!loading"
                @click="cancel()"
                class="btn__small--black btn__small--topright float-right"
              >
                <i class="fas fa-times"></i>
              </span>
              <div class="orm-row">
                <div class="orm-col-xs-12">
                  <Stationpicker :data="stations" type="start" />
                  <Stationpicker
                    :data="stations"
                    type="end"
                    v-if="
                      store.getters['ORMgeneral/GET_PARAMETER'](
                        'BINET_ALLOW_ONEWAY'
                      ) == 1
                    "
                  />
                </div>
                <div class="orm-relative orm-col-xs-12">
                  <Datepicker :infobar="false" viewType="mapModule" />
                </div>
                <div class="orm-relative orm-col-xs-12">
                  <button
                    @click="getCars('/reservation/step1')"
                    class="orm-btn orm-btn--large"
                    :class="[!loading ? '' : 'disable']"
                  >
                    <i
                      v-if="loading"
                      class="fa fa-spinner-third orm-rotate360"
                    ></i>
                    {{ i18n("general/showOffers") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
// import routes from "@/router/index";
// eslint-disable-next-line
import store from "@/store";
import Datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src

import Stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src
export default {
  name: "modal-stationtimeselect",
  components: { Datepicker, Stationpicker },
  emits: ["cancel"],
  props: {
    car: Object,
    showCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  setup() {
    const stations = store.getters["stations/GET_STATIONS"];
    const station = store.getters["userSession/GET_STATION"];
    return {
      stations,
      station,
      store,
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflow = "unset";
  },
  methods: {
    cancel() {
      // console.log("cancel");
      this.$emit("cancel");
    },
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];
      this.loading = true;
      if (times) {
        if (this.showCategory) {
          const group = {};
          group[this.car.category] = true;
          store.dispatch("userSession/SET_CARCATEGORY", group);
          if (process.env.VUE_APP_STANDALONE === "true") {
            this.$router.push({ name: "Reservation_Step1" });
          } else {
            window.location.href =
              process.env.VUE_APP_BASE_URL +
              process.env.VUE_APP_RESERVATION_PATH +
              process.env.VUE_APP_RESERVATION_PATH_STEP1;
          }
        } else {
          store.dispatch("userSession/SET_CARGROUP", this.car.id).then(() => {
            store.dispatch("reservation/DELETE_TARIF");
            store.dispatch("stations/SET_OPENINGTIMES").then(() => {
              store
                .dispatch("reservation/CALCULATE_PRICE", {
                  id: this.car.id,
                  extras: false,
                })
                .then((tarif) => {
                  // console.log(tarif);
                  // console.log(tarif.main_rates.length);
                  if (tarif.main_rates.length == 0) {
                    if (process.env.VUE_APP_STANDALONE == "true") {
                      this.$router.push({ name: "Reservation_Step1" });
                    } else {
                      window.location.href =
                        process.env.VUE_APP_BASE_URL +
                        process.env.VUE_APP_RESERVATION_PATH +
                        process.env.VUE_APP_RESERVATION_PATH_STEP1;
                    }
                  } else {
                    store
                      .dispatch("reservation/SET_RESERVATION_TARIFS", tarif)
                      .then(() => {
                        if (process.env.VUE_APP_STANDALONE == "true") {
                          this.$router.push({ name: "Reservation_Step2" });
                        } else {
                          window.location.href =
                            process.env.VUE_APP_BASE_URL +
                            process.env.VUE_APP_RESERVATION_PATH +
                            process.env.VUE_APP_RESERVATION_PATH_STEP2;
                        }
                      });
                  }
                });
            });
          });
        }
      }
    },
  },
};
</script>
