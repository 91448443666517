<template>
  <teleport v-if="widget" to="#crentCarfilter ">
    <div
      class="orm-py-56 orm-py-sm-70 orm-container-fluid orm-background__image"
      v-if="clientdata"
      :style="{
        backgroundImage: 'url(' + backgroundImage + ')',
      }"
    >
      <div class="orm-container">
        <div class="orm-row center-xs">
          <div class="orm-col-xs-12 text-center">
            <h1 class="orm-searchbar__title orm-headline__h1 orm-pb-sm-128">
              <span v-if="clientdata['carsearchfilter_title']">
                {{ clientdata["carsearchfilter_title"] }}
              </span>
              <span v-else>{{ i18n("modules/carsearchfilter/title") }}</span>
            </h1>
          </div>
        </div>
        <div class="orm-row center-xs">
          <div
            class="orm-carsearchfilter orm-col-xs-12 orm-col-sm-11 orm-col-md-11 orm-col-lg-11"
          >
            <div class="orm-carsearchfilter__types">
              <div class="orm-carsearchfilter__typescontainer orm-flex">
                <div
                  class="orm-carsearchfilter__typesitem"
                  :class="
                    selectedCarType === 'auto' || selectedCarType === ''
                      ? 'active'
                      : ''
                  "
                  @click="setCarType('auto')"
                >
                  <i class="fas fa-car orm-mr-8"></i> Auto
                </div>
                <div
                  class="orm-carsearchfilter__typesitem"
                  :class="selectedCarType === 'transporter' ? 'active' : ''"
                  @click="setCarType('transporter')"
                >
                  <i class="fas fa-truck orm-mr-8"></i> Transporter
                </div>
                <div
                  class="orm-carsearchfilter__typesitem"
                  :class="selectedCarType === 'anhaenger' ? 'active' : ''"
                  @click="setCarType('anhaenger')"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="trailer"
                    class="fassvg-inline--fa fa-trailer fa-w-18 orm-mr-8"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M624,320H544V80a16,16,0,0,0-16-16H16A16,16,0,0,0,0,80V368a16,16,0,0,0,16,16H65.61c7.83-54.21,54-96,110.39-96s102.56,41.79,110.39,96H624a16,16,0,0,0,16-16V336A16,16,0,0,0,624,320ZM96,243.68a176.29,176.29,0,0,0-32,20.71V136a8,8,0,0,1,8-8H88a8,8,0,0,1,8,8Zm96-18.54c-5.31-.49-10.57-1.14-16-1.14s-10.69.65-16,1.14V136a8,8,0,0,1,8-8h16a8,8,0,0,1,8,8Zm96,39.25a176.29,176.29,0,0,0-32-20.71V136a8,8,0,0,1,8-8h16a8,8,0,0,1,8,8ZM384,320H352V136a8,8,0,0,1,8-8h16a8,8,0,0,1,8,8Zm96,0H448V136a8,8,0,0,1,8-8h16a8,8,0,0,1,8,8Zm-304,0a80,80,0,1,0,80,80A80,80,0,0,0,176,320Zm0,112a32,32,0,1,1,32-32A32,32,0,0,1,176,432Z"
                    ></path>
                  </svg>
                  Anhänger
                </div>
              </div>
            </div>
            <div class="orm-carsearchfilter_row ">
              <div class="orm-flex">
                <div class="orm-carfilter__element orm-px-16 orm-py-16" v-if="groupFilter.length > 0">
                  <CarCategoryFilter
                    :groupFilter="groupFilter"
                    :hide-selection="true"
                    @update:dataPool="updateFilter"
                  />
                </div>
                <div class="orm-carfilter__element orm-px-16 orm-py-16" v-if="equipment.length > 0">
                  <CarCategoryFilter
                    :title="i18n('modules/carsearchfilter/equipment')"
                    filter-type="equipment"
                    :groupFilter="equipment"
                    :hide-selection="true"
                    @update:dataPool="updateEqFilter"
                  />
                </div>
              </div>
            </div>
            <div class="orm-carsearchfilter_row orm-carsearchfilter_row--rounded">
              <div class="orm-searchbar__padding" v-if="ormload">
                <div class="orm-row center-lg">
                  <div class="orm-col-xs-12 orm-col-md-12 orm-col-lg-3">
                    <div class="orm-searchbar__headline">
                      <strong>{{ i18n("general/station") }}</strong>
                    </div>
                    <div class="orm-row">
                      <stationpicker
                        :data="stations"
                        type="start"
                        class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                      />
                      <stationpicker
                        v-if="
                          store.getters['ORMgeneral/GET_PARAMETER'](
                            'BINET_ALLOW_ONEWAY'
                          ) == 1
                        "
                        :data="stations"
                        type="end"
                        class="orm-col-xs-12 orm-col-sm-6 orm-col-lg-12"
                      />
                    </div>
                  </div>
                  <div
                    class="orm-col-xs-12 orm-col-lg-7"
                    style="position:relative"
                  >
                    <datepicker />
                  </div>
                  <div
                    class="text-right orm-col-xs-12 orm-col-sm-6 orm-col-sm-offset-6 orm-col-lg-2 orm-col-lg-offset-0"
                  >
                    <div class="orm-searchbar__headline">
                      <strong>&nbsp;</strong>
                    </div>
                    <button
                      v-if="!loading"
                      @click="getCars('/reservation/step1')"
                      class="orm-btn orm-btn--large orm-btn--searchbar"
                      :class="[station ? '' : 'disable']"
                    >
                      {{ i18n("general/search") }}
                    </button>
                    <button
                      class="orm-btn orm-btn--large orm-btn--searchbar disable"
                      v-else
                    >
                      <i class="fa fa-spinner-third orm-rotate360"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";

import datepicker from "@/components/ui/datepicker.vue"; // @ is an alias to /src
import stationpicker from "@/components/ui/stationpicker.vue"; // @ is an alias to /src

import CarCategoryFilter from "@/components/ui/filter-select"; // @ is an alias to /src

import store from "@/store";
// import routes from "@/router/index";

export default defineComponent({
  name: "CarFilter",
  components: { datepicker, stationpicker, CarCategoryFilter },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentCarfilter");
    if (myEle !== null) {
      widget = true;
    }

    return {
      widget,
      store,
    };
  },
  props: {
    origin: {
      type: String,
      default: "home",
      required: false,
    },
  },
  data() {
    return {
      station: Object,
      loading: false,
      ormload: false,
      clientdata: null,
      backgroundImage: "",
      stations: {},
      groupFilter: [],
      selectedGroup: [],
      equipment: [],
      selectedCarType: "auto",
    };
  },
  async mounted() {
    this.ormload = await store.getters["general/GET_ORMLOADED"];
    this.stations = await store.getters["stations/GET_STATIONS"];
    this.station = await store.getters["userSession/GET_STATION"];
    this.clientdata = await store.getters["general/GET_CLIENT_DATA"];
    this.carGroup = await store.getters["cargroup/GET_GLOBAL_CARGROUPS"];
    this.selectedCarType = await store.getters["userSession/GET_CARTYPE"];
    if (this.carGroup.length <= 0) {
      await store.dispatch("cargroup/SET_CARGROUPS_ALL");
    }

    this.groupFilter = await store.getters["cargroup/GET_GLOBAL_GROUPFILTER"];
    this.equipment = await store.dispatch("cargroup/GET_EQUIPMENT");
    // eslint-disable-next-line
    if (this.clientdata["searchbar_background"]) {
      // eslint-disable-next-line
      this.backgroundImage = this.clientdata["searchbar_background"];
    }
    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["general/GET_ORMLOADED"],
      (val) => {
        this.ormload = val;
        this.stations = store.getters["stations/GET_STATIONS"];
        this.station = store.getters["userSession/GET_STATION"];
      }
    );

    store.watch(
      // eslint-disable-next-line
      (state, getters) => getters["userSession/GET_STATION"],
      (val) => {
        this.station = store.getters["stations/GET_STATION_BY_ID"](val);
      }
    );
  },
  methods: {
    async getCars() {
      const times = await store.getters["stations/GET_OPENINGTIMES"];

      if (times) {
        this.loading = true;
        if (process.env.VUE_APP_STANDALONE == "true") {
          this.$router.push({ name: "Reservation_Step1" });
        } else {
          const baseUrl = window.location.origin;
          window.location.href =
            baseUrl +
            process.env.VUE_APP_BASE_URL +
            process.env.VUE_APP_RESERVATION_PATH +
            process.env.VUE_APP_RESERVATION_PATH_STEP1;
        }
      }
    },
    updateFilter(ev) {
      this.selectedGroup = ev.selectedGroup;
      this.selectedAll = ev.selectedAll;
    },
    updateEqFilter() {
      // console.log(ev);
    },
    setCarType(cat) {
      this.selectedCarType = cat;
      store.dispatch(
        "userSession/SET_CARTYPE",
        JSON.parse(JSON.stringify(this.selectedCarType))
      );
    },
  },
});
</script>
