
import { defineComponent } from "vue";

import OffersSlider from "@/components/widgets/OffersSlider.vue"; // @ is an alias to /src
import CarCategorySlider from "@/components/widgets/CarCategorySlider.vue"; // @ is an alias to /src
import LocationMap from "@/components/widgets/LocationMap.vue"; // @ is an alias to /src
import SearchBar from "@/components/widgets/SearchBar.vue"; // @ is an alias to /src
import CarFilter from "@/components/widgets/CarFilter.vue"; // @ is an alias to /src

import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src

import store from "@/store";

//import store from "../store";

export default defineComponent({
  name: "Home",
  components: {
    OffersSlider,
    CarCategorySlider,
    CarFilter,
    LocationMap,
    SearchBar,
    Footer,
    Header,
  },
  data() {
    return {
      env: process.env,
    };
  },
  mounted() {
    store.dispatch("userSession/SET_CARCATEGORY", {});
    store.dispatch("userSession/SET_EQUIPMENT", {});
    store.dispatch("userSession/SET_CARTYPE", "");
    store.dispatch("payment/DELETE_CONARDIS");
    store.dispatch("reservation/DELETE_SUCCESS_RESERVATION");
  },
});
