
import { defineComponent } from "vue";
import { mapSettings } from "@/constants/mapSettings";

import Footer from "@/components/widgets/Footer.vue"; // @ is an alias to /src
import Header from "@/components/widgets/Header.vue"; // @ is an alias to /src
import store from "@/store";
import reservationinfobar from "@/components/reservation/infobar_reservation.vue"; // @ is an alias to /src
import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";
import { formateDateShort, formatTime } from "@/constants/formater";

export default defineComponent({
  name: "Reservation/Final",
  components: {
    Footer,
    Header,
    reservationinfobar,
    gMap,
    gMapMarker,
  },
  setup() {
    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };
    return {
      mapConfig,
      formateDateShort,
      formatTime,
    };
  },
  data() {
    return {
      data: Object,
      session: Object,
      startstation: Object,
      endstation: Object,
      apiKey: process.env.VUE_APP_MAPS_API_KEY,
      bounds: [],
      hasConcardis: false,
    };
  },

  async mounted() {
    this.data = await store.getters["reservation/GET_RESERVATION_SUCCESS"];
    store.dispatch("reservation/DELETE_TARIF");
    store.dispatch("payment/DELETE_CONARDIS");
    // console.log(this.data);

    if (this.data == null) {
      this.$router.push({ name: "Home" });
    }
    const object = this.data["invoice_positions"];

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];
        if (element.rate == process.env.VUE_APP_PAYMENT_ONLINE) {
          this.hasConcardis = true;
        }
      }
    }
    // console.log(store.getters["reservation/GET_RESERVATION"]);
    // this.data = this.$route.params.data;
  },
});
