<template>
  <teleport v-if="widget" to="#crentHeader">
    <div class="orm-header">
      <div class="orm-container">
        <div class="orm-row middle-xs">
          <div class="orm-col-xs-5 orm-col-sm-5">
            <div class="orm-reservationHeader__logo">
              <router-link
                v-if="env.VUE_APP_STANDALONE == 'true'"
                :to="{ name: 'Home' }"
                title="Home"
              >
                <div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      store.getters['general/GET_CLIENT_DATA'][
                        'reservationheader-logo'
                      ] +
                      ')',
                    height: logoheight + 'px',
                  }"
                ></div>
              </router-link>
              <a v-else :href="env.VUE_APP_BASE_URL"
                ><div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      store.getters['general/GET_CLIENT_DATA'][
                        'reservationheader-logo'
                      ] +
                      ')',
                    height: logoheight + 'px',
                  }"
                ></div
              ></a>
            </div>
          </div>
          <div class="orm-col-xs-12 orm-col-sm-7 orm-pb-16 orm-pb-sm-0">
            <ul class="orm-headerNavigation text-right">
              <li>
                <router-link :to="{ name: 'fahrzeuge' }">{{
                  i18n("general/vehicles")
                }}</router-link>
              </li>
              <li>
                <dropdown :data="stations" :label="i18n('general/locations')" />
              </li>
              <li
                v-if="
                  store.getters['ORMgeneral/GET_MODULE']('ORM_LOGIN') == 'J'
                "
              >
                <div>{{ i18n("general/mylogin") }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";

import dropdown from "@/components/ui/dropdown.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Footer",
  components: { dropdown },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentHeader");
    if (myEle !== null) {
      widget = true;
    }
    const stations = store.getters["stations/GET_STATIONS"];
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_HOME;
    const env = process.env;
    // const stationsCS = [];
    // for (const station in stations) {
    //   if (stations[station].is_sharing_station == true) {
    //     stationsCS.push(stations[station]);
    //   }
    // }
    // console.log(store.state.stations);

    return {
      widget,
      store,
      stations,
      logoheight,
      env
      // stationsCS,
    };
  },
});
</script>
