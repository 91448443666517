import apiCall from "../../api/crentAPI";
import store from "@/store";
import { formateDatetime, formatHour } from "@/constants/formater";
const ENV = process.env.NODE_ENV === "production" ? "ORM_" : "";

const state = () => ({
  reservation:
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservation")) || null,
  customer:
    JSON.parse(sessionStorage.getItem(ENV + "selectedCustomer")) || null,
  payment: JSON.parse(sessionStorage.getItem(ENV + "selectedPayment")) || null,
  paymenttype:
    JSON.parse(sessionStorage.getItem(ENV + "selectedPaymenttype")) || {},
  paymentmethod:
    JSON.parse(sessionStorage.getItem(ENV + "selectedPaymentmethod")) || {},
  extras: null,
  insurance:
    JSON.parse(sessionStorage.getItem(ENV + "selectedInsurance")) || {},
  selectedExtras:
    JSON.parse(sessionStorage.getItem(ENV + "selectedExtras")) || {},
  selectedDate:
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationDate")) || {},
  selectedTime:
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationTime")) || {},
  selectedStation:
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationStation")) ||
    {},
  selectedCargroup:
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationCargroup")) ||
    {},
  selectedDistance:
    JSON.parse(sessionStorage.getItem(ENV + "selectedDistance")) || 0,
  selectedDeliveryPickup: JSON.parse(
    sessionStorage.getItem(ENV + "selectedDeliveryPickup")
  ) || {
    deliveryStreet: "",
    deliveryZipcode: "",
    deliveryCity: "",
    pickupStreet: "",
    pickupZipcode: "",
    pickupCity: "",
  },
});

const getters = {
  GET_RESERVATION: (state) => {
    const reservation =
      JSON.parse(sessionStorage.getItem(ENV + "selectedReservation")) ||
      state.reservation;
    return reservation;
  },
  GET_RESERVATION_SUCCESS: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "reservationSuccess")) ||
    state.reservation ||
    null,
  GET_CUSTOMER: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedCustomer")) ||
    state.customer,
  GET_EXTRA_CATEGORIES: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "tarifExtras")) ||
    state.extras ||
    null,
  GET_PAYMENT_METHOD: (state) => (input) => {
    if (state.payment.find((payment) => payment.id === parseInt(input))) {
      return state.payment.find((payment) => payment.id === parseInt(input))
        .payment_method;
    } else {
      return null;
    }
  },
  GET_PAYMENT_SELECTION: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedPaymentmethod")) ||
    state.paymentmethod ||
    null,
  GET_INSURANCE: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedInsurance")) ||
    state.insurance ||
    null,
  GET_PAYMENTTYPE: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedPaymenttype")) ||
    state.paymenttype ||
    null,
  GET_PAYMENTTYPE_OFFLINE: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedPaymenttypeOffline")) ||
    state.selectedPaymenttypeOffline ||
    null,
  GET_PAYMENTTYPE_ONLINE: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedPaymenttypeOnline")) ||
    state.selectedPaymenttypeOnline ||
    null,

  GET_EXTRAS: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedExtras")) ||
    state.selectedExtras ||
    {},
  GET_RESERVATION_DATE: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationDate")) ||
    state.selectedDate ||
    {},
  GET_RESERVATION_TIME: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationTime")) ||
    state.selectedTime ||
    {},
  GET_RESERVATION_STATION: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationStation")) ||
    state.selectedTime ||
    {},
  GET_RESERVATION_CARGROUP: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedReservationCargroup")) ||
    state.selectedCargroup ||
    {},
  GET_RESERVATION_DISTANCE: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedDistance")) ||
    state.selectedDistance ||
    0,
  GET_DELIVERY_PICKUP: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedDeliveryPickup")) ||
    state.selectedDeliveryPickup,
};

// actions
const actions = {
  // eslint-disable-next-line
  async CALCULATE_PRICE({ commit, getters, dispatch }, calculate) {
    const date = await store.getters["userSession/GET_RESERVATIONDATE"];
    const time = await store.getters["userSession/GET_RESERVATIONTIME"];
    const startstation = await store.getters["userSession/GET_STATION"];
    const endstation = await store.getters["userSession/GET_STATION_END"];
    const distance = await getters["GET_RESERVATION_DISTANCE"];
    const insurance = await getters["GET_INSURANCE"];
    const paymenttype = await getters["GET_PAYMENTTYPE"];
    const extras = await getters["GET_EXTRAS"];
    const extraArray = [];
    const deliveryPickup = await store.getters[
      "reservation/GET_DELIVERY_PICKUP"
    ];

    if (calculate.extras) {
      if (calculate.payment) {
        extraArray.push({
          id: calculate.payment[0],
          amount: calculate.payment[1],
        });
      } else {
        for (const extra in paymenttype) {
          if (Object.hasOwnProperty.call(paymenttype, extra)) {
            extraArray.push({
              id: paymenttype[extra].id,
              amount: paymenttype[extra].amount,
            });
          }
        }
      }

      for (const extra in insurance) {
        if (Object.hasOwnProperty.call(insurance, extra)) {
          extraArray.push(insurance[extra]);
        }
      }
      for (const extra in extras) {
        // console.log('extraArray', extra);
        if (Object.hasOwnProperty.call(extras, extra)) {
          extraArray.push(extras[extra]);
        }
      }
    }

    let startTimeDate = "";
    let endTimeDate = "";
    let st = "";
    const timeNow = formatHour(new Date());

    // let ed = '';
    // let et = '';
    if (date.start) {
      if (time.start) {
        st = time.start + ":00";
      } else {
        st = timeNow + ":00";
      }
      startTimeDate = date.start + "T" + st;
    } else {
      startTimeDate = formateDatetime(Date.now());
    }
    if (date.end) {
      // console.log('time', time.end);
      if (time.end) {
        st = time.end + ":00";
      } else {
        st = timeNow + ":00";
      }
      endTimeDate = date.end + "T" + st;
    } else {
      endTimeDate = formateDatetime(Date.now());
    }

    /* eslint-disable */
    const payload = {
      startdate: startTimeDate,
      planned_enddate: endTimeDate,
      startstation_id: startstation,
      planned_endstation_id: endstation,
      planned_group_id: calculate.id,
      delivery_street: deliveryPickup.deliveryStreet,
      delivery_zipcode: deliveryPickup.deliveryZipcode,
      delivery_city: deliveryPickup.deliveryCity,
      pickup_street: deliveryPickup.pickupStreet,
      pickup_zipcode: deliveryPickup.pickupZipcode,
      pickup_city: deliveryPickup.pickupCity,
      distance: distance,
      extras: extraArray,
    };

    /* eslint-enable */
    let priceObject;
    //  + station // soon station ...
    await apiCall.post(
      "/api/rent/bookings/calculate",
      JSON.stringify(payload),
      (result) => {
        priceObject = result;
      }
    );
    return priceObject;
  },
  // eslint-disable-next-line
  async SET_CATEGORIES({ commit, dispatch }, mainRate) {
    const date = await store.getters["userSession/GET_RESERVATIONDATE"];
    const time = await store.getters["userSession/GET_RESERVATIONTIME"];
    const startstation = await store.getters["userSession/GET_STATION"];
    const endstation = await store.getters["userSession/GET_STATION_END"];
    const groupID = await store.getters["userSession/GET_CARGROUP"];

    // REMOVE STORAGE
    sessionStorage.removeItem(ENV + "selectedInsurance");
    sessionStorage.removeItem(ENV + "selectedExtras");

    let startTimeDate = "";
    let endTimeDate = "";
    let st = "";
    const timeNow = formatHour(new Date());

    if (date.start) {
      if (time.start) {
        st = time.start + ":00";
      } else {
        st = timeNow + ":00";
      }
      startTimeDate = date.start + "T" + st;
    } else {
      startTimeDate = formateDatetime(Date.now());
    }
    if (date.end) {
      // console.log('time', time.end);
      if (time.end) {
        st = time.end + ":00";
      } else {
        st = timeNow + ":00";
      }
      endTimeDate = date.end + "T" + st;
    } else {
      endTimeDate = formateDatetime(Date.now());
    }

    // console.log('MAINRATE', mainRate.rate);

    /* eslint-disable */
    const payload = {
      startdate: startTimeDate,
      planned_enddate: endTimeDate,
      startstation_id: startstation,
      planned_endstation_id: endstation,
      planned_group_id: groupID,
      main_rate: mainRate.rate,
    };

    /* eslint-enable */
    let categories = [];
    await apiCall.get("/api/rent/rates/categories", "", (result) => {
      categories = result;
    });

    categories.forEach((category, index) => {
      categories[index].extras = [];
    });
    /// SINCE CX9 is wokring - BEGIN
    categories.unshift({ id: "CDW", name: "Versicherungsschutz", extras: [] });
    categories.unshift({ id: "PAY_", name: "Bezahlung", extras: [] });
    categories.unshift({ id: "others", name: "", extras: [] });
    /// SINCE CX9 is wokring - END

    let extrasResult = [];
    await apiCall.get("/api/rent/rates/extras", payload, (result) => {
      extrasResult = result;
    });

    const extras = [];

    extrasResult.forEach((extra) => {
      /// SINCE CX9 is wokring - BEGIN
      if (extra.rate.search("CDW") >= 0) {
        if (categories.find((x) => x.id === extra.category)) {
          categories.find((x) => x.id === "CDW").name = categories.find(
            (x) => x.id === extra.category
          ).name;
        }
        categories.find((x) => x.id === "CDW").extras.push(extra);
        categories
          .find((x) => x.id === "CDW")
          .extras.sort((a, b) => (a.single_price > b.single_price ? 1 : -1));
        if (extra.required == true) {
          const payload = {
            rate: extra.rate,
            id: extra.id,
            amount: extra.amount,
          };
          dispatch("SET_INSURANCE", payload);
        }
      } else if (
        extra.rate.search("PAY_") >= 0 &&
        store.getters["ORMgeneral/GET_MODULE"]("ConCardis") == "J"
      ) {
        if (categories.find((x) => x.id === extra.category)) {
          categories.find((x) => x.id === "PAY_").name = categories.find(
            (x) => x.id === extra.category
          ).name;
        }
        categories.find((x) => x.id === "PAY_").extras.push(extra);
        categories
          .find((x) => x.id === "PAY_")
          .extras.sort((a, b) => (a.single_price > b.single_price ? 1 : -1));
        if (extra.rate === process.env.VUE_APP_PAYMENT_OFFLINE) {
          const payload = {
            rate: extra.rate,
            id: extra.id,
            amount: extra.amount,
            price: extra.total_long_price,
          };
          dispatch("SET_PAYMENTTYPE_OFFLINE", payload);
        }
        if (extra.rate === process.env.VUE_APP_PAYMENT_ONLINE) {
          const payload = {
            rate: extra.rate,
            id: extra.id,
            amount: extra.amount,
            price: extra.total_long_price,
          };
          dispatch("SET_PAYMENTTYPE_ONLINE", payload);
        }
        if (
          extra.required == true ||
          extra.rate === process.env.VUE_APP_PAYMENT_ONLINE
        ) {
          const payload = {
            rate: extra.rate,
            id: extra.id,
            amount: extra.amount,
            price: extra.total_long_price,
          };
          dispatch("SET_PAYMENTTYPE", payload);
        }
      } /// SINCE CX9 is wokring - END
      else if (categories.find((x) => x.id === extra.category) !== undefined) {
        categories.find((x) => x.id === extra.category).extras.push(extra);
        if (extra.required == true) {
          const payload = {
            rate: extra.rate,
            id: extra.id,
            amount: extra.amount,
          };
          dispatch("SET_EXTRAS", payload);
        }
      } else {
        categories.find((x) => x.id === "others").extras.push(extra);
      }
    });
    categories.forEach((category, index) => {
      if (categories[index].extras.length > 0) extras.push(categories[index]);
    });
    commit("MUTATE_CATEGORIES", extras);

    return extras;
  },
  SET_RESERVATION_TARIFS({ commit }, reservation) {
    commit("MUTATE_RESERVATION", reservation);
    commit("MUTATE_RESERVATION_CARGROUP");
    commit("MUTATE_RESERVATION_STATION");
    commit("MUTATE_RESERVATION_DATETIME");
  },
  SET_CUSTOMER({ commit }, customer) {
    commit("MUTATE_CUSTOMER", customer);
  },
  SET_MARKETING({ commit }, marketing) {
    commit("MUTATE_MARKETING", marketing);
  },
  SET_PAYMENTMETHOD({ commit }, type) {
    commit("MUTATE_PAYMENTMETHOD", type);
  },
  SET_INSURANCE({ commit }, insurance) {
    commit("MUTATE_INSURANCE", insurance);
  },
  SET_PAYMENTTYPE({ commit }, type) {
    commit("MUTATE_PAYMENTTYPE", type);
  },
  SET_PAYMENTTYPE_OFFLINE({ commit }, type) {
    commit("MUTATE_PAYMENTTYPE_OFFLINE", type);
  },
  SET_PAYMENTTYPE_ONLINE({ commit }, type) {
    commit("MUTATE_PAYMENTTYPE_ONLINE", type);
  },
  SET_EXTRAS({ commit }, extras) {
    commit("MUTATE_EXTRAS", extras);
  },
  SET_DRIVEDISTANCE({ commit }, distance) {
    commit("MUTATE_DRIVEDISTANCE", distance);
  },
  SET_DELIVERY_PICKUP({ commit }, input) {
    commit("MUTATE_DELIVERY_PICKUP", input);
  },
  DELETE_TARIF({ commit }) {
    commit("MUTATE_DELETE_RESERVATION");
  },
  DELETE_SUCCESS_RESERVATION({ commit }) {
    commit("DELETE_SUCCESS_RESERVATION");
  },
  async GET_PAYMENT_METHODS({ commit }) {
    const payload = "";
    let methods;
    await apiCall.get(
      "/api/rent/bookings/payments/methods",
      payload,
      (result) => {
        methods = result;
        commit("MUTATE_PAYMENTMETHODS", result);
      }
    );

    return methods;
  },
  /* eslint-disable */
  async RESERVATE({ commit, state }) {
    const date = await store.getters["userSession/GET_RESERVATIONDATE"];
    const time = await store.getters["userSession/GET_RESERVATIONTIME"];
    const startstation = await store.getters["userSession/GET_STATION"];
    const endstation = await store.getters["userSession/GET_STATION_END"];
    const groupID = await store.getters["userSession/GET_CARGROUP"];

    const reservationdistance = await store.getters[
      "reservation/GET_RESERVATION_DISTANCE"
    ];
    const deliveryPickup = await store.getters[
      "reservation/GET_DELIVERY_PICKUP"
    ];

    let startTimeDate = "";
    let endTimeDate = "";
    let st = "";
    const timeNow = formatHour(new Date());

    if (date.start) {
      if (time.start) {
        st = time.start + ":00";
      } else {
        st = timeNow + ":00";
      }
      startTimeDate = date.start + "T" + st;
    } else {
      startTimeDate = formateDatetime(Date.now());
    }
    if (date.end) {
      // console.log('time', time.end);
      if (time.end) {
        st = time.end + ":00";
      } else {
        st = timeNow + ":00";
      }
      endTimeDate = date.end + "T" + st;
    } else {
      endTimeDate = formateDatetime(Date.now());
    }

    const customer = state.customer;

    const reservation = state.reservation;

    const invoicepositions = [];
    for (const key in reservation.main_rates) {
      if (Object.hasOwnProperty.call(reservation.main_rates, key)) {
        const element = reservation.main_rates[key].id;
        if (element)
          invoicepositions.push({ id: reservation.main_rates[key].id });
      }
    }
    for (const key in reservation.insurance_rates) {
      if (Object.hasOwnProperty.call(reservation.sub_rates, key)) {
        const element = reservation.insurance_rates[key].id;
        if (element)
          invoicepositions.push({ id: reservation.insurance_rates[key].id });
      }
    }
    for (const key in reservation.sub_rates) {
      if (Object.hasOwnProperty.call(reservation.sub_rates, key)) {
        const element = reservation.sub_rates[key].id;
        if (element)
          invoicepositions.push({ id: reservation.sub_rates[key].id });
      }
    }
    for (const key in reservation.delivery_pickup_rates) {
      if (Object.hasOwnProperty.call(reservation.delivery_pickup_rates, key)) {
        const element = reservation.delivery_pickup_rates[key].id;
        if (element)
          invoicepositions.push({
            id: reservation.delivery_pickup_rates[key].id,
          });
      }
    }
    for (const key in reservation.end_positions) {
      if (Object.hasOwnProperty.call(reservation.end_positions, key)) {
        const element = reservation.end_positions[key].id;
        if (element)
          invoicepositions.push({ id: reservation.end_positions[key].id });
      }
    }

    let bookingPaymentType = null;
    if (store.getters["ORMgeneral/GET_MODULE"]("ConCardis") == "J") {
      const concardis = await store.dispatch("payment/SET_ORDER");
      if (concardis) {
        if (concardis.paymentInstrument) {
          bookingPaymentType = {
            orderId: concardis.orderId,
            paymentInstrumentId:
              concardis.paymentInstrument.paymentInstrumentId,
            transactionId: concardis.transactions[0].transactionId,
            customerId: concardis.customer.customerId,
            paymentInstrumentType: concardis.paymentInstrument.type,
          };
        }
      }
    }
    const payload = {
      startdate: startTimeDate,
      planned_enddate: endTimeDate,
      startstation_id: startstation,
      planned_endstation_id: endstation,
      planned_group_id: groupID,
      customer: customer.customer,
      payment_method_id: customer.paymentMethod_id,
      distance: reservationdistance,
      remarks: customer.remarks,
      invoice_positions: invoicepositions,
      delivery_street: deliveryPickup.deliveryStreet,
      delivery_zipcode: deliveryPickup.deliveryZipcode,
      delivery_city: deliveryPickup.deliveryCity,
      pickup_street: deliveryPickup.pickupStreet,
      pickup_zipcode: deliveryPickup.pickupZipcode,
      pickup_city: deliveryPickup.pickupCity,
      bookingPaymentType: bookingPaymentType,
    };
    let priceObject;
    //  + station // soon station ...
    await apiCall.postHeaders(
      "/api/rent/bookings",
      JSON.stringify(payload),
      (result) => {
        priceObject = result;
        if (result.status == 201) {
          commit("MUTATE_SUCCESS_RESERVATION", priceObject);
          commit("MUTATE_DELETE_RESERVATION");
        }
      }
    );

    return priceObject;
  },
};
/* eslint-enable */
// mutations
const mutations = {
  MUTATE_CATEGORIES(state, cats) {
    state.extras = cats;
    sessionStorage.setItem(ENV + "tarifExtras", JSON.stringify(cats));
  },
  MUTATE_PAYMENTMETHODS(state, data) {
    state.payment = data;
    sessionStorage.setItem(ENV + "selectedPayment", JSON.stringify(data));
  },
  MUTATE_RESERVATION(state, reservation) {
    state.reservation = reservation;
    sessionStorage.setItem(
      ENV + "selectedReservation",
      JSON.stringify(reservation)
    );
  },
  MUTATE_RESERVATION_CARGROUP(state) {
    const session = JSON.parse(localStorage.getItem(ENV + "userSession"));
    state.selectedCargroup = session.cargroup;
    sessionStorage.setItem(
      ENV + "selectedReservationCargroup",
      JSON.stringify(state.selectedCargroup)
    );
    // console.log("state.reservation", state.reservation);
  },
  MUTATE_RESERVATION_STATION(state) {
    const session = JSON.parse(localStorage.getItem(ENV + "userSession"));
    state.selectedStation = {
      start: session.selectedStation,
      end: session.selectedStationEnd,
    };
    sessionStorage.setItem(
      ENV + "selectedReservationStation",
      JSON.stringify(state.selectedStation)
    );
  },
  MUTATE_RESERVATION_DATETIME(state) {
    const session = JSON.parse(localStorage.getItem(ENV + "userSession"));
    state.selectedDate = session.reservationDate;
    state.selectedTime = session.reservationTime;
    sessionStorage.setItem(
      ENV + "selectedReservationDate",
      JSON.stringify(state.selectedDate)
    );
    sessionStorage.setItem(
      ENV + "selectedReservationTime",
      JSON.stringify(state.selectedTime)
    );
  },
  MUTATE_CUSTOMER(state, customer) {
    state.customer = customer;
    sessionStorage.setItem(ENV + "selectedCustomer", JSON.stringify(customer));
  },
  /* eslint-disable */
  MUTATE_MARKETING(state, marketing) {
    state.customer.customer.allow_marketing = marketing;
    sessionStorage.setItem(
      ENV + "selectedCustomer",
      JSON.stringify(state.customer)
    );
    /* eslint-enable */
  },
  MUTATE_SUCCESS_RESERVATION(state, reservation) {
    state.success = reservation;
    sessionStorage.setItem(
      ENV + "reservationSuccess",
      JSON.stringify(reservation.data)
    );
  },
  DELETE_SUCCESS_RESERVATION(state) {
    delete state.success;
    sessionStorage.removeItem(ENV + "reservationSuccess");
  },
  MUTATE_PAYMENTMETHOD(state, payment) {
    state.paymentmethod = payment;

    sessionStorage.setItem(
      ENV + "selectedPaymentmethod",
      JSON.stringify(state.paymentmethod)
    );
  },
  MUTATE_INSURANCE(state, insurance) {
    state.insurance = {};
    if (insurance.rate !== undefined) {
      state.insurance[insurance.rate] = {
        id: insurance.id,
        amount: insurance.amount,
      };
    }

    sessionStorage.setItem(
      ENV + "selectedInsurance",
      JSON.stringify(state.insurance)
    );
  },
  MUTATE_PAYMENTTYPE(state, type) {
    state.paymenttype = {};
    if (type.rate !== undefined) {
      state.paymenttype[type.rate] = {
        id: type.id,
        amount: type.amount,
        price: type.price,
      };
    }

    sessionStorage.setItem(
      ENV + "selectedPaymenttype",
      JSON.stringify(state.paymenttype)
    );
  },
  MUTATE_PAYMENTTYPE_OFFLINE(state, type) {
    state.paymenttypeoffline = {};
    if (type.rate !== undefined) {
      state.paymenttypeoffline[type.rate] = {
        id: type.id,
        amount: type.amount,
        price: type.price,
      };
    }
    sessionStorage.setItem(
      ENV + "selectedPaymenttypeOffline",
      JSON.stringify(state.paymenttypeoffline)
    );
  },
  MUTATE_PAYMENTTYPE_ONLINE(state, type) {
    state.paymenttypeonline = {};
    if (type.rate !== undefined) {
      state.paymenttypeonline[type.rate] = {
        id: type.id,
        amount: type.amount,
        price: type.price,
      };
    }
    sessionStorage.setItem(
      ENV + "selectedPaymenttypeOnline",
      JSON.stringify(state.paymenttypeonline)
    );
  },
  MUTATE_EXTRAS(state, extras) {
    if (state.selectedExtras == null) {
      state.selectedExtras = {};
    }
    const extra = state.selectedExtras[extras.rate];
    if (extra && extras.amount == 0) {
      delete state.selectedExtras[extras.rate];
    } else {
      state.selectedExtras[extras.rate] = {
        id: extras.id,
        amount: extras.amount,
      };
    }
    sessionStorage.setItem(
      ENV + "selectedExtras",
      JSON.stringify(state.selectedExtras)
    );
  },
  MUTATE_DRIVEDISTANCE(state, distance) {
    state.selectedDistance = JSON.parse(JSON.stringify(distance));
    sessionStorage.setItem(
      ENV + "selectedDistance",
      JSON.stringify(state.selectedDistance)
    );
  },
  MUTATE_DELIVERY_PICKUP(state, input) {
    state.selectedDeliveryPickup = JSON.parse(JSON.stringify(input));
    sessionStorage.setItem(
      ENV + "selectedDeliveryPickup",
      JSON.stringify(state.selectedDeliveryPickup)
    );
  },
  MUTATE_DELETE_RESERVATION(state) {
    state.extras = null;
    delete state.paymenttypeoffline;
    delete state.paymenttypeonline;
    delete state.paymenttype;
    state.insurance = null;
    state.selectedExtras = null;
    state.selectedStation = null;
    state.selectedCargroup = null;
    state.selectedDate = null;
    state.selectedTime = null;
    state.reservation = null;
    state.selectedDistance = 0;
    state.selectedDeliveryPickup = {
      deliveryStreet: "",
      deliveryZipcode: "",
      deliveryCity: "",
      pickupStreet: "",
      pickupZipcode: "",
      pickupCity: "",
    };
    sessionStorage.removeItem(ENV + "selectedInsurance");
    sessionStorage.removeItem(ENV + "selectedExtras");
    sessionStorage.removeItem(ENV + "selectedReservation");
    sessionStorage.removeItem(ENV + "selectedReservationCargroup");
    sessionStorage.removeItem(ENV + "selectedReservationStation");
    sessionStorage.removeItem(ENV + "selectedReservationDate");
    sessionStorage.removeItem(ENV + "selectedReservationTime");
    sessionStorage.removeItem(ENV + "selectedDistance");
    sessionStorage.removeItem(ENV + "selectedDeliveryPickup");
    sessionStorage.removeItem(ENV + "tarifExtras");

    sessionStorage.removeItem(ENV + "selectedPaymenttype");
    sessionStorage.removeItem(ENV + "selectedPaymenttypeOffline");
    sessionStorage.removeItem(ENV + "selectedPaymenttypeOnline");
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
