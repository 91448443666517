<template>
  <teleport v-if="widget" to="#crentLocationMap">
    <div
      class="orm-locationMap orm-pb-60 orm-container-fluid orm-background__image"
      :style="{
        backgroundImage: 'url(' + clientdata['locationmap_background'] + ')',
      }"
    >
      <div class="orm-container">
        <div class="orm-row center-xs">
          <div class="orm-col-xs-12 orm-col-sm-11 orm-px-0">
            <h2 class="orm-locationMap__title">
              <span
                v-if="clientdata['locationmap_title']"
                v-html="clientdata['locationmap_title']"
              ></span>
              <span v-else> {{ i18n("modules/locationmap/title") }}</span>
            </h2>
            <div class="orm-row no-gutter">
              <div
                style="position:relative"
                class="orm-col-xs-12 orm-col-md-7 orm-col-lg-9 orm-locationMap__mapContainer orm-MapModule"
              >
                <div style="position:absolute;top:0;right:0">
                  <div id="v-range-selector-map"></div>
                </div>
                <gMap
                  :mapConfig="mapConfig"
                  :apiKey="apiKey"
                  v-slot="{ google, map, bounds }"
                  v-if="cookiefound.maps"
                >
                  <div
                    v-for="marker in stations.stationlist"
                    :key="marker.station"
                  >
                    <gMapMarker
                      v-if="marker.latitude != 0 && marker.latitude != null"
                      :marker="marker"
                      :google="google"
                      :map="map"
                      :bounds="bounds"
                      @marker-click="infoWindow"
                    />
                  </div>
                  <gMapUser :google="google" :map="map" :bounds="bounds" />
                </gMap>
                <div
                  v-else
                  style="background-color:#fff;height:100%;align-items:center;display:flex"
                >
                  <div
                    class="orm-px-32"
                    v-html="i18n('modules/locationmap/noconsent')"
                  ></div>
                </div>
              </div>
              <div class="orm-col-xs-12 orm-col-md-5 orm-col-lg-3 ">
                <stationlist />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapSettings } from "@/constants/mapSettings";
import { getDistanceFromLatLonInKm } from "@/constants/formater";

import { defineComponent, watchEffect, reactive } from "vue";

import gMap from "@/components/maps/gMap.vue";
import gMapMarker from "@/components/maps/gMapMarker.vue";
import gMapUser from "@/components/maps/gMapUser.vue";

import stationlist from "@/components/stationlist.vue";

import store from "@/store";

export default defineComponent({
  name: "LocationMap",
  components: { gMap, gMapMarker, gMapUser, stationlist },
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentLocationMap");
    if (myEle !== null) {
      widget = true;
    }

    const clientdata = store.getters["general/GET_CLIENT_DATA"];
    const stations = store.state.stations;
    // let selectedStation = store.getters["userSession/GET_STATION"];
    let selectedStation = "";
    const userSession = store.state.userSession.LocalSession;

    const cookiefound = reactive({'maps':false});

    //  document.cookie = 'cookie_consent={"options": ["gmap"]}';
    // document.cookie = "gmap=1";

    if (process.env.VUE_APP_COOKIECONSENT === "true") {

      const COOKIE = Symbol("Cookie");
      let lastCookies = document.cookie;

      const getCookie = (cname) => {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(lastCookies);
        const ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return JSON.parse(c.substring(name.length, c.length));
          }
        }
        return false;
      };

      const checkMapCookie = () => {
        if (process.env.VUE_APP_COOKIECONSENTARRAY === "true") {
          const cookie = getCookie(process.env.VUE_APP_COOKIECONSENTCOOKIENAME)
            .options;
          if (cookie) {
            cookiefound.maps = cookie.find(
              (element) =>
                element === process.env.VUE_APP_COOKIECONSENTCOOKIEMAPSNAME
            );
          }
        } else {
          if (
            getCookie(process.env.VUE_APP_COOKIECONSENTCOOKIEMAPSNAME) ==
            (true || 1)
          ) {
            cookiefound.maps = true;
          } else {
            cookiefound.maps = false;
          }
        }
      };

      Object.defineProperty(
        Document.prototype,
        COOKIE,
        Object.getOwnPropertyDescriptor(Document.prototype, "cookie")
      );

      Object.defineProperty(Document.prototype, "cookie", {
        enumerable: true,
        configurable: true,
        get() {
          return this[COOKIE];
        },
        set(value) {
          this[COOKIE] = value;

          if (value === lastCookies) {
            return;
          }
          lastCookies = value;
          checkMapCookie();
        },
      });
      checkMapCookie();
    } else {
      cookiefound.maps = true;
    }
    watchEffect(() => {
      selectedStation = store.getters["userSession/GET_STATION"];
    });

    const apiKey = clientdata["maps_apikey"];

    const mapConfig = {
      ...mapSettings,
      center: { lat: 52.29240999999999, lng: 8.920219999999972 },
    };

    return {
      widget,
      stations,
      selectedStation,
      getDistanceFromLatLonInKm,
      userSession,
      mapConfig,
      store,
      clientdata,
      apiKey,
      cookiefound,
    };
  },
  methods: {
    infoWindow() {
      // console.log("Click: ", ev);
    },
  },
});
</script>
