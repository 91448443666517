import apiCall from "../../api/crentAPI";

const state = () => ({
  modules: [],
  parameters: [],
});

const getters = {
  GET_MODULE: (state) => (module) => {
    //till login is facable - todo CX9
    if (module == "ORM_LOGIN") return "N";
    if (module == "ConCardis" && process.env.VUE_APP_CONCARDIS === "false")
      return "N";
    return state.modules.find((modules) => modules.module === module).enabled;
  },
  GET_PARAMETER: (state) => (parameter) => {
    return state.parameters.find(
      (parameters) => parameters.parameter === parameter
    ).value;
  },
};

const actions = {
  async SET_MODULES({ commit }) {
    let status = false;
    await apiCall.get("/api/rent/configuration/modules", "", (result) => {
      // console.log("error", result);
      if (result.error || result.errorCode) {
        status = false;
        return ["ORMgeneral/SET_MODULES", status];
      } else {
        status = true;
      }
      commit("MUTATE_MODULES", result);
    });
    return ["ORMgeneral/SET_MODULES", status];
  },
  async SET_PARAMETER({ commit }) {
    let status = false;
    await apiCall.get("/api/rent/configuration/parameters", "", (result) => {
      if (result.error || result.errorCode) {
        status = false;
        return ["ORMgeneral/SET_PARAMETER", status];
      } else {
        status = true;
      }
      commit("MUTATE_PARAMETERS", result);
    });
    return ["ORMgeneral/SET_PARAMETER", status];
  },
  async SET_CURRENCY({ commit }) {
    await apiCall.get("/api/rent/currencies", "", (result) => {
      commit("MUTATE_CURRENCY", result);
    });
    return true;
  },
};
const mutations = {
  // eslint-disable-next-line
  MUTATE_MODULES(state, modules) {
    state.modules = modules;
    // /api/rental/customers/countries
  },
  // eslint-disable-next-line
  MUTATE_PARAMETERS(state, parameters) {
    state.parameters = parameters;
    // /api/rental/customers/salutations
  },
  // eslint-disable-next-line
  MUTATE_CURRENCY(state, parameters) {
    state.currency = parameters;
    // /api/rental/customers/salutations
  },
};
export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
